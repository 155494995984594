import React, { useState } from "react";
import { Typography } from "antd";
import moment from "moment";
import { MDBBadge, MDBIcon } from "mdb-react-ui-kit";

//#endregion
export const EllipseText = (props) => {
  const { Text } = Typography;
  const [ellipsis, setEllipsis] = useState(true);
  return (
    <>
      <Text
        style={
          ellipsis
            ? {
                width: 240,
              }
            : undefined
        }
        ellipsis={
          ellipsis
            ? {
                tooltip: props.text,
              }
            : false
        }
      >
        {props.text}
      </Text>
    </>
  );
};

//#endregion
export const taskPriorityBadge = (name) => {
  let priorityIcon = (
    <MDBIcon
      style={{ color: "#ffa900", marginLeft: "15px" }}
      fas
      icon="arrow-up"
    />
  );
  if (name == "HIGH") {
    priorityIcon = (
      <MDBIcon
        style={{ color: "red", marginLeft: "15px" }}
        fas
        icon="arrow-up"
      />
    );
  } else if (name == "LOW") {
    priorityIcon = (
      <MDBIcon
        style={{ color: "green", marginLeft: "15px" }}
        fas
        icon="arrow-down"
      />
    );
  }
  return <>{priorityIcon}</>;
};

export const taskStatusBadge = (name) => {
  // status bg
  let statusBg = (
    <MDBBadge
      className="project-task-open"
      style={{ borderRadius: "4px", color: "#404040" }}
      color="light"
    >
      {name}
    </MDBBadge>
  );

  // let statusBg = 'shadow-0';
  if (name == "IN PROGRESS") {
    statusBg = (
      <MDBBadge className="status-blue" style={{ borderRadius: "4px" }}>
        {name}
      </MDBBadge>
    );
  } else if (name == "DEVELOPMENT DONE") {
    statusBg = (
      <MDBBadge className="status-blue" style={{ borderRadius: "4px" }}>
        {name}
      </MDBBadge>
    );
  } else if (name == "REJECTED") {
    statusBg = <MDBBadge style={{ borderRadius: "4px" }}>{name}</MDBBadge>;
  } else if (name == "ON HOLD") {
    statusBg = <MDBBadge style={{ borderRadius: "4px" }}>{name}</MDBBadge>;
  } else if (name == "READY FOR QA") {
    statusBg = (
      <MDBBadge className="status-blue" style={{ borderRadius: "4px" }}>
        {name}
      </MDBBadge>
    );
  } else if (name == "REOPEN") {
    statusBg = (
      <MDBBadge className="badge-light" style={{ borderRadius: "4px" }}>
        {name}
      </MDBBadge>
    );
  } else if (name == "READY FOR UAT") {
    statusBg = (
      <MDBBadge
        className="status-green"
        style={{ borderRadius: "4px" }}
        color="success"
      >
        {name}
      </MDBBadge>
    );
  } else if (name == "CLOSE") {
    statusBg = (
      <MDBBadge
        className="status-green"
        style={{ borderRadius: "4px" }}
        color="success"
      >
        {name}
      </MDBBadge>
    );
  }
  return <>{statusBg}</>;
};
export const projectStatusBadge = (name) => {
  // status bg
  let statusBg = (
    <MDBBadge
      className="project-task-open"
      // style={{ borderRadius: '7px', color: '#404040' }}
      // color="light"
    >
      {name}
    </MDBBadge>
  );

  // let statusBg = 'shadow-0';
  if (name == "In Progress") {
    statusBg = (
      <MDBBadge className="status-blue1" style={{ borderRadius: "4px" }}>
        {name}
      </MDBBadge>
    );
  } else if (name == "Pending") {
    statusBg = (
      <MDBBadge className="status-grey1" style={{ borderRadius: "4px" }}>
        {name}
      </MDBBadge>
    );
  } else if (name == "On Hold") {
    statusBg = (
      <MDBBadge className="status-grey1" style={{ borderRadius: "4px" }}>
        {name}
      </MDBBadge>
    );
  } else if (name == "In Client Review") {
    statusBg = (
      <MDBBadge className="status-green1" style={{ borderRadius: "4px" }}>
        {name}
      </MDBBadge>
    );
  } else if (name == "Client Not Responding") {
    statusBg = (
      <MDBBadge
        className="status-red1"
        style={{ borderRadius: "4px" }}
        color="success"
      >
        {name}
      </MDBBadge>
    );
  }
  return <>{statusBg}</>;
};
export const MyprojectStatusBadge = (name) => {
  // status bg
  let statusBg = (
    <MDBBadge
      className="project-task-open"
      // style={{ borderRadius: '7px', color: '#404040' }}
      // color="light"
    >
      {name}
    </MDBBadge>
  );

  // let statusBg = 'shadow-0';
  if (name == "InProgress") {
    statusBg = (
      <MDBBadge className="status-blue1" style={{ borderRadius: "4px" }}>
        {name}
      </MDBBadge>
    );
  } else if (name == "Hold") {
    statusBg = (
      <MDBBadge className="status-grey1" style={{ borderRadius: "4px" }}>
        {name}
      </MDBBadge>
    );
  } else if (name == "Open") {
    statusBg = (
      <MDBBadge className="status-grey1" style={{ borderRadius: "4px" }}>
        {name}
      </MDBBadge>
    );
  } else if (name == "InClientReview") {
    statusBg = (
      <MDBBadge className="status-green1" style={{ borderRadius: "4px" }}>
        {name}
      </MDBBadge>
    );
  } else if (name == "Completed") {
    statusBg = (
      <MDBBadge
        className="status-green1"
        style={{ borderRadius: "4px" }}
        color="success"
      >
        {name}
      </MDBBadge>
    );
  }

  return <>{statusBg}</>;
};

// give range between two dates
const getDateRange = (firstDate, lastDate) => {
  if (
    moment(firstDate, "YYYY-MM-DD").isSame(
      moment(lastDate, "YYYY-MM-DD"),
      "day"
    )
  ) {
    return [firstDate];
  }

  let date = moment(firstDate).format("YYYY-MM-DD");
  const dates = [date];

  while (moment(date).isBefore(lastDate)) {
    date = moment(date).add(1, "day").format("YYYY-MM-DD");

    if (moment(lastDate).format("YYYY-MM-DD") >= date) {
      dates.push(date);
    }
  }

  return dates;
};

// getCount of days between two dates and exclude SAT & SUN
const getCountDays = (firstDate, lastDate) => {
  let totalDiffDays;
  const newStartDate = moment(firstDate);
  const newEndDate = moment(lastDate);

  // check differenece of days from start & end Date
  let dateRange = getDateRange(newStartDate, newEndDate);
  let weekendDaysCount = 0;
  for (let i = 0; i < dateRange.length; i++) {
    let weekDays = moment(dateRange[i]).day();
    if (weekDays == 6 || weekDays == 0) {
      weekendDaysCount = weekendDaysCount + 1;
    }
  }

  let diffBetweenDates = newEndDate.diff(newStartDate, "days");
  totalDiffDays = diffBetweenDates - weekendDaysCount + 1;

  return totalDiffDays;
};

export const LeaveDates = ({ items }) => {
  const dateArray = items?.Leave_Date_to_Date?.split(",");
  const newStartDate = new Date(dateArray[0]);
  const newEndDate = new Date(dateArray[1]);
  const halfStart = items.Leave_half_from;
  const halfTo = items.Leave_half_to;
  let appliedDate = "";
  let days = "";
  if (
    moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY") ===
      moment(newEndDate, "DD/MM/YYYY").format("MMM DD, YYYY") &&
    halfStart === "First Half" &&
    halfTo === "Second Half"
  ) {
    appliedDate = moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY");
    days = "1 Day";
  } else {
    appliedDate =
      moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY") +
      " - " +
      moment(newEndDate, "DD/MM/YYYY").format("MMM DD, YYYY");
    if (
      moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY") ===
      moment(newEndDate, "DD/MM/YYYY").format("MMM DD, YYYY")
    ) {
      appliedDate = moment(newStartDate, "DD/MM/YYYY").format("MMM DD, YYYY");
      if (halfStart === "Second Half") {
        days = "0.5 Day (Second Half)";
      } else {
        days = "0.5 Day (First Half)";
      }
    } else {
      let daysLeft = getCountDays(newStartDate, newEndDate);
      console.log(daysLeft, ":daysLeft", newStartDate, newEndDate);

      // let daysLeft =
      //   moment(newEndDate, 'DD/MM/YYYY').diff(
      //     moment(newStartDate, 'DD/MM/YYYY'),
      //     'days',
      //   ) + 1
      if (halfStart == "First Half" && halfTo === "Second Half") {
        days = daysLeft + " Days";
      } else {
        if (halfStart === "Second Half") {
          daysLeft -= 0.5;
        }
        if (halfTo === "First Half") {
          daysLeft -= 0.5;
        }
        days = daysLeft + " Days (" + halfStart + " - " + halfTo + ")";
      }
    }
  }

  return (
    <>
      {appliedDate} <span className="info">{days}</span>{" "}
    </>
  );
};
