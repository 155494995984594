import React from "react";
import { MDBCard } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const IMG_URL = "https://bucket-pms.s3.amazonaws.com";

function AttendanceImage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const image = queryParams.get("image");

  return (
    <>
      <div className="image-attendance-tab-main">
        <div className="image-attendance-tab">
          {image && <img src={`${IMG_URL}/${image}`} alt="Attendance" />}
        </div>
      </div>
    </>
  );
}

export default AttendanceImage;
