import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import ReactLoader from "../../components/common/ReactLoader";
import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux";
import { Routes } from "../../routes/routes";

const loading = () => <ReactLoader />;

const DefaultLayout = () => {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [checkInCheckOut, setCheckInCheckOut] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const permissionsState = useSelector(
    (state) => state?.Permissions?.Permissions
  );

  useEffect(() => {
    const isValidToken = (accessToken) => {
      if (!accessToken) return false;
      const decodedToken = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp > currentTime;
    };

    const interval = setInterval(() => {
      if (!isValidToken(token)) {
        clearInterval(interval);
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        history.push("/login");
      }
    }, 600000);

    return () => clearInterval(interval);
  }, [token, history]);

  const handleModeChange = () => {
    setDarkMode(!darkMode);
    if (darkMode) {
      document.body.classList.add("darkmode-modal");
    } else {
      document.body.classList.remove("darkmode-modal");
    }
  };

  const hasPermission = (route) => {
    if (route?.objectkey === undefined) {
      return true;
    }
    return permissionsState[route?.objectkey]?.[route?.permissionKey] === "1";
  };

  // Determine if the current path should exclude the header and sidebar
  const excludeHeaderAndSidebarPaths = ["/report/attendance/image"];
  const currentPath = history.location.pathname;

  return (
    <div>
      {token ? (
        <div className={`flexible-content ${darkMode ? "darkmode" : ""}`}>
          {!excludeHeaderAndSidebarPaths.includes(currentPath) && <Sidebar />}
          {!excludeHeaderAndSidebarPaths.includes(currentPath) && (
            <Header
              checkInCheckOut={checkInCheckOut}
              updateMode={handleModeChange}
            />
          )}
          <main id="content">
            <React.Suspense fallback={loading()}>
              <Switch>
                {Routes?.map((route, idx) => {
                  return hasPermission(route)
                    ? route.component && (
                        <Route
                          key={idx}
                          path={route?.path}
                          exact={route?.exact}
                          name={route?.name}
                          render={(props) => (
                            <route.component
                              {...props}
                              setCheckInCheckOut={setCheckInCheckOut}
                            />
                          )}
                        />
                      )
                    : null;
                })}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </React.Suspense>
          </main>
          {!excludeHeaderAndSidebarPaths.includes(currentPath) && <Footer />}
        </div>
      ) : (
        <Redirect to="/login" />
      )}
    </div>
  );
};

export default DefaultLayout;
