import React, { useEffect, useState } from "react";
import { Form, Modal, Table } from "antd";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBIcon,
  MDBNavbarNav,
  MDBDropdownToggle,
  MDBDropdownLink,
  MDBDropdownItem,
  MDBDropdown,
  MDBDropdownMenu,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import logo3 from "../../assets/images/logo3.png";
import { Button, Drawer } from "antd";
import { DropboxOutlined, MenuOutlined } from "@ant-design/icons";
import Checkinout from "../../container/pages/checkinout/Checkinout";
import MyReport from "../../container/pages/eventdashboard/MyReport/MyReport";
import AxiosInstance from "../../service/AxiosInstance";
import moment from "moment";
import { AllPermissions } from "../../redux/actions/PermissionActions";
import { useDispatch, useSelector } from "react-redux";
import Marquee from "react-fast-marquee";
import { Dropdown, Space } from "antd";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const imageUrl = "https://bucket-pms.s3.ap-south-1.amazonaws.com/s3-upload/";
export default function Header(props) {
  const permssionsState = useSelector((state) => state.Permissions.Permissions);

  let history = useHistory();
  let userData = JSON.parse(localStorage.getItem("userdata")) || {};
  const location = useLocation();
  const [showNavNoTogglerSecond, setShowNavNoTogglerSecond] = useState(false);
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("userdata");
    history.push("/login");
    window.location.reload();
  };
  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const [profilePic, setProfilePic] = useState("");
  const [leaveData, setLeaveData] = useState({});
  const [rolePermissions, setRolepermissions] = useState(permssionsState);
  const [totalWorkingHOurs, settotalWorkingHOurs] = useState("");
  const [myReport, setMyReport] = useState([]);
  const [myTask, setMyTask] = useState([]);
  const [isBlinking, setIsBlinking] = useState(false);

  // New
  const toDayDate = new Date();
  // New
  const firstDayOfMonth = new Date(
    toDayDate.getFullYear(),
    toDayDate.getMonth(),
    1
  );
  // New
  const isWeekend =
    firstDayOfMonth.getDay() === 0 || firstDayOfMonth.getDay() === 6;
  // New
  if (isWeekend) {
    firstDayOfMonth.setDate(
      firstDayOfMonth.getDate() +
        (firstDayOfMonth.getDay() === 0
          ? 1
          : firstDayOfMonth.getDay() === 6
          ? 2
          : firstDayOfMonth.getDay())
    );
  }
  // New
  const isSameDateAsFirstDayOfMonth =
    toDayDate.getDate() === firstDayOfMonth.getDate();
  // New
  const [attendanaceMarqueeVisible, setAttendanaceMarqueeVisible] = useState(
    isSameDateAsFirstDayOfMonth
  );

  // New
  const currentDate = new Date();
  // New
  currentDate.setMonth(currentDate.getMonth() - 1);
  // New
  const previousMonth = currentDate.toLocaleString("default", {
    month: "long",
  });
  // New
  const year = currentDate.getFullYear();

  const items = [
    {
      key: "1",
      label: "Employee",
      children: [
        {
          key: "2-1",
          label: (
            <Link to="/employees">
              <MDBNavbarLink> Employee </MDBNavbarLink>
            </Link>
          ),
        },
      ],
    },
    {
      key: "2",
      label: "Report",
      children: [
        {
          key: "2-1",
          label:
            rolePermissions?.Report?.Leave_Report == "1" ? (
              <MDBDropdownItem>
                <Link to="/leaves-report">
                  <MDBDropdownLink>Leave Report</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "2-2",
          label:
            rolePermissions?.Report?.Ticket_Report == "1" ? (
              <MDBDropdownItem>
                <Link to="/tickets-report">
                  <MDBDropdownLink>Ticket Report</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "2-3",
          label:
            rolePermissions?.Report?.Face_Report == "1" ? (
              <MDBDropdownItem>
                <Link to="/report/attendance">
                  <MDBDropdownLink>Face Report</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "2-4",
          label:
            rolePermissions?.Report?.Manual_Attendance_Request == "1" ? (
              <MDBDropdownItem>
                <Link to="/checkInRequest">
                  <MDBDropdownLink>Attendance Request</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "2-5",
          label:
            rolePermissions?.Report?.Attendance_Summary == "1" ? (
              <MDBDropdownItem>
                <Link to="/report/attendance-summary">
                  <MDBDropdownLink>Attendance Summary</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "2-6",
          label:
            rolePermissions?.Report?.Attendance_Summary == "1" ? (
              <MDBDropdownItem>
                <Link to="/report/kra">
                  <MDBDropdownLink>KRA</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "2-7",
          label:
            rolePermissions?.Report?.Attendance_Summary == "1" ? (
              <MDBDropdownItem>
                <Link to="/EmployeeWorkingHours">
                  <MDBDropdownLink>Employee Working Hours</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
      ],
    },
    {
      key: "3",
      label: "PM-Report",
      children: [
        {
          key: "3-1",
          label:
            rolePermissions?.PM_Report?.Employee_Tracker == "1" ? (
              <MDBDropdownItem>
                <MDBNavbarItem>
                  <Link to="/employee-tracker">
                    <MDBNavbarLink>Employee Tracker</MDBNavbarLink>
                  </Link>
                </MDBNavbarItem>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "3-2",
          label:
            rolePermissions?.PM_Report?.Project_Working_Hours == "1" ? (
              <MDBDropdownItem>
                <MDBNavbarItem>
                  <Link to="/Project-Working-Hours">
                    <MDBNavbarLink>Project-Working-Hours</MDBNavbarLink>
                  </Link>
                </MDBNavbarItem>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "3-3",
          label:
            rolePermissions?.PM_Report?.Seven_Days_Working_plan == "1" ? (
              <MDBDropdownItem>
                <MDBNavbarItem>
                  <Link to="/employeeWorkingPlan">
                    <MDBNavbarLink>7 Days Working plan</MDBNavbarLink>
                  </Link>
                </MDBNavbarItem>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "3-4",
          label:
            rolePermissions?.PM_Report?.Milestone_Report == "1" ? (
              <MDBDropdownItem>
                <Link to="/milestone/report">
                  <MDBDropdownLink>Milestone Report</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "3-5",
          label:
            rolePermissions?.PM_Report?.Project_Status_Report == "1" ? (
              <MDBDropdownItem>
                <Link to="/report/project-status">
                  <MDBDropdownLink>Project Status Report</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "3-6",
          label:
            rolePermissions?.PM_Report?.Sprint_Report == "1" ? (
              <MDBDropdownItem>
                <Link to="/sprint/report">
                  <MDBDropdownLink>Sprint Report</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "3-7",
          label:
            rolePermissions?.PM_Report?.Task_Report == "1" ? (
              <MDBDropdownItem>
                <Link to="/reportList">
                  <MDBDropdownLink>Task Report</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "3-8",
          label:
            rolePermissions?.PM_Report?.Task_Timer_Report == "1" ? (
              <MDBDropdownItem>
                <Link to="/tasks/tasktimer/report">
                  <MDBDropdownLink>Task Timer Report</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        // {
        //   key: "3-9",
        //   label: (
        //     // rolePermissions?.PM_Report?.Story_Tracker == "1" ? (
        <MDBDropdownItem>
          <MDBNavbarItem>
            <Link to="/story-tracker">
              <MDBNavbarLink>Story Tracker</MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
        </MDBDropdownItem>,
        //   ),
        //   // ) : (
        //   //   ""
        //   // ),
        // },

        {
          key: "3-10",
          label:
            rolePermissions?.PM_Report?.Employee_Hours == "1" ? (
              <MDBDropdownItem>
                <Link to="/employee-hours">
                  <MDBDropdownLink>Employee Hours List</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
      ],
    },
    {
      key: "4",
      label: "Master",
      children: [
        {
          key: "4-1",
          label:
            rolePermissions?.Master?.Position == "1" ? (
              <MDBDropdownItem>
                <Link to="/positions">
                  <MDBDropdownLink>Position</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "4-2",
          label: (
            <MDBDropdownItem>
              <Link to="/roles-permission">
                <MDBDropdownLink>Roles Permission</MDBDropdownLink>
              </Link>
            </MDBDropdownItem>
          ),
        },
        {
          key: "4-3",
          label:
            rolePermissions?.Master?.Department == "1" ? (
              <MDBDropdownItem>
                <Link to="/department">
                  <MDBDropdownLink>Department</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "4-4",
          label:
            rolePermissions?.Master?.Holidays == "1" ? (
              <MDBDropdownItem>
                <Link to="/Holidays">
                  <MDBDropdownLink>Holidays</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "4-5",
          label:
            rolePermissions?.Master?.Ticket_Department == "1" ? (
              <MDBDropdownItem>
                <Link to="/ticket-department">
                  <MDBDropdownLink>Ticket Department</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "4-6",
          label:
            rolePermissions?.Master?.Location == "1" ? (
              <MDBDropdownItem>
                <Link to="/location">
                  <MDBDropdownLink>Location</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "4-7",
          label:
            rolePermissions?.Master?.Vertical == "1" ? (
              <MDBDropdownItem>
                <Link to="/vertical">
                  <MDBDropdownLink>Vertical</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "4-8",
          label:
            rolePermissions?.Master?.Attendance_Device == "1" ? (
              <MDBDropdownItem>
                <Link to="/attendance-device">
                  <MDBDropdownLink>Attendance Device</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "4-9",
          label:
            rolePermissions?.Master?.Configuration == "1" ? (
              <MDBDropdownItem>
                <Link to="/configuration">
                  <MDBDropdownLink>Configuration</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "4-10",
          label:
            rolePermissions?.Master?.EscalationType == "1" ? (
              <MDBDropdownItem>
                <Link to="/escalationType">
                  <MDBDropdownLink>Escalation Type</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
        {
          key: "4-11",
          label:
            rolePermissions?.Master?.ShiftMaster == "1" ? (
              <MDBDropdownItem>
                <Link to="/shiftMaster">
                  <MDBDropdownLink>Shift Master</MDBDropdownLink>
                </Link>
              </MDBDropdownItem>
            ) : (
              ""
            ),
        },
      ],
    },
  ];

  useEffect(() => {
    // Toggle blinking effect every 1 second
    const interval = setInterval(() => {
      setIsBlinking((prevState) => !prevState);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const addClass = () => {
    document.body.classList.add("header-dropdown-main");
  };

  const dispatch = useDispatch();
  let user_data = JSON.parse(localStorage.getItem("userdata"));
  let Code = user_data?.[0]?.Employee_code;

  const getRolePermissionList = async () => {
    const data = new URLSearchParams();
    data.append("id", userData[0]?.Role_permission_id);
    await AxiosInstance.post("auth/permission/details?fromheader", data)
      .then((res) => {
        if (res.data.status == 1) {
          setRolepermissions(res.data.data[0]);
          dispatch(AllPermissions(res.data.data[0]));
        }
      })
      .catch((e) => {
        if (e.response.status == 402) {
          // if token is expred the ask for the login
          logout();
        }
      });
  };

  useEffect(() => {
    getRolePermissionList();
    // data()
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setProfilePic(userData[0]?.Profile_pic);
  }, []);

  const getLeaveData = () => {
    if (Code) {
      const formData = new URLSearchParams();

      formData.append("Code", Code);
      formData.append("month", moment().month());

      AxiosInstance.post("getEmployeePresentDetailsByCode", formData).then(
        (res) => {
          if (res.data.status == 200) {
            if (res?.data?.data?.length) {
              setLeaveData(res.data.data);
            }
          }
        }
      );
    }
  };

  useEffect(() => {
    Data();
  }, [rolePermissions]);

  const getDefaultReport = () => {
    AxiosInstance.get("auth/working-hours").then((res) => {
      setMyReport(res.data.data);
    });
  };
  const getDaysArray = function (year, month) {
    let monthIndex = month - 1;
    let names = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let date = new Date(year, monthIndex, 1);
    let result = [];

    while (date.getMonth() == monthIndex) {
      if (
        names[date.getDay()] !== "Saturday" &&
        names[date.getDay()] !== "Sunday"
      ) {
        let dateModify =
          date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        result.push(dateModify + "-" + names[date.getDay()]);
      }
      date.setDate(date.getDate() + 1);
    }
    return result;
  };
  let curMonth = new Date().getMonth();
  let curYear = new Date().getFullYear();
  let res = getDaysArray(curYear, curMonth + 1);
  let currDay = `${moment().format("DD")}-${moment().format("dddd")}`;

  let addTimes = (timearr) => {
    let duration = 0;
    timearr.forEach((time) => {
      duration = duration + moment.duration(time).as("milliseconds");
    });
    return moment.utc(duration).format("HH:mm:ss");
  };

  const GetMyReport = () => {
    let data = [];

    let timearr = [];
    myReport?.map((row) => {
      var duration = moment.duration(
        moment(row?.Check_Out).diff(moment(row.Check_In))
      );
      var seconds = duration.asSeconds();

      let minutes = Math.floor(seconds) % 3600;

      let tasktime =
        Math.floor(seconds / 3600) +
        ":" +
        Math.floor(minutes / 60) +
        ":" +
        Math.floor(seconds % 60);
      timearr.push(tasktime);

      let Working_HOURS = addTimes(timearr);
      let Totaltime = Working_HOURS.split(":");
      let modifyTime = Totaltime[0] + ":" + Totaltime[1] + ":" + Totaltime[2];
      settotalWorkingHOurs(modifyTime);
    });
    setMyTask(data);
  };

  useEffect(() => {
    GetMyReport();
  }, [myReport]);

  useEffect(() => {
    getDefaultReport();
  }, []);

  const Data = () => {
    return (
      <>
        {rolePermissions?.Employee !== undefined &&
        rolePermissions?.Employee !== null &&
        Object.values(rolePermissions?.Employee).includes("1") ? (
          <MDBNavbarItem>
            <Link to="/employees">
              <MDBNavbarLink>Employee</MDBNavbarLink>
            </Link>
          </MDBNavbarItem>
        ) : (
          ""
        )}
      </>
    );
  };
  useEffect(() => {
    getLeaveData();
  }, [Code]);

  let date = moment(leaveData?.[0]?.Date).format("DD/MM/YYYY");
  const dataSource = [
    {
      key: "1",
      name: "Date",
      age: `${date}`,
    },
    {
      key: "1",
      name: "Name",
      age: `${leaveData?.[0]?.Name}`,
    },
    {
      key: "1",
      name: "Code",
      age: `${leaveData?.[0]?.Code}`,
    },
    {
      key: "1",
      name: "Working Days",
      age: `${leaveData?.[0]?.Working_days}`,
    },
    {
      key: "1",
      name: "Full Leave",
      age: `${leaveData?.[0]?.Full_leave}`,
    },
    {
      key: "1",
      name: "Half Leave",
      age: `${leaveData?.[0]?.Half_leave}`,
    },
    {
      key: "1",
      name: "Missed_punch",
      age: `${leaveData?.[0]?.Missed_punch}`,
    },
    {
      key: "1",
      name: "WFM",
      age: `${leaveData?.[0]?.WFM}`,
    },
    {
      key: "1",
      name: "Carry Forward",
      age: `${leaveData?.[0]?.Carry_forward}`,
    },

    {
      key: "1",
      name: "Total Days",
      age: `${leaveData?.[0]?.Total_days}`,
    },
    {
      key: "1",
      name: "Allow",
      age: `${leaveData?.[0]?.Allow}`,
    },
    {
      key: "1",
      name: "Grand_Total",
      age: `${leaveData?.[0]?.Grand_Total}`,
    },
    {
      key: "1",
      name: "Attendence Status",
      age: `${leaveData?.[0]?.Attendence_Status}`,
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
  ];
  const handleCancel = (errorInfo) => {
    form.resetFields();
    setVisible(false);
  };

  const UpdateStatus = (status) => {
    if (status == "Approved") {
      const formData = new URLSearchParams();
      formData.append("Code", Code);
      formData.append("month", moment().month());
      formData.append("Attendence_Status", "Approved");

      AxiosInstance.post("LeaveAttendenceStatusUpdate", formData).then(
        (res) => {
          // setLeaveAttendenceStatus(res.data.data[0].Attendence_Status)
        }
      );
    }
    if (status == "Issue") {
      const formData = new URLSearchParams();
      formData.append("Code", Code);
      formData.append("month", moment().month());
      formData.append("Attendence_Status", "Issue");

      AxiosInstance.post("LeaveAttendenceStatusUpdate", formData).then(
        (res) => {}
      );
    }
  };

  function getNextWorkingDay() {
    const currentDate = moment();
    let deadlineDate = currentDate.clone();

    if (currentDate.day() === 0 || currentDate.day() === 6) {
      deadlineDate.add(1, "days");
    }

    while (deadlineDate.day() === 0 || deadlineDate.day() === 6) {
      deadlineDate.add(1, "days");
    }

    return deadlineDate.date();
  }

  return (
    <>
      <MDBNavbar
        expand="lg"
        light
        bgColor="new-head top-header mobile-header-img"
      >
        <a
          href="#!"
          className="logo-wrapper waves-effect logo-vh-set mobilemenu-logo"
        >
          <img alt="MDB React Logo" className="img-fluid" src={logo3} />
        </a>

        <MDBContainer fluid className="mob-main">
          {/* <MDBNavbarToggler
            type="button"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowNavNoTogglerSecond(!showNavNoTogglerSecond)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler> */}
          <MDBCollapse navbar show={showNavNoTogglerSecond}>
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 employee-set">
              {/* <MDBNavbarItem>
                <Link to="/dashboard">
                  {' '}
                  <MDBNavbarLink active aria-current="page" href="#">
                    Home
                  </MDBNavbarLink>
                </Link>
              </MDBNavbarItem>  */}
              {/* <MDBNavbarItem>
                <Link to="/projects">
                  <MDBNavbarLink>Project</MDBNavbarLink>
                </Link>
              </MDBNavbarItem>  */}
              {/* {1 ? ( */}
              {/* {permissions[0]?.All == '1' ? ( */}

              {
                // console.log(  , 340)
              }

              <>
                {/* {Object.keys(rolePermissions1?.Employee || {}) &&  Object.values(rolePermissions1?.Employee || {}).includes(1) ? (
                  <MDBNavbarItem>
                    <Link to="/employees">
                      <MDBNavbarLink>Employee</MDBNavbarLink>
                    </Link>
                  </MDBNavbarItem>
                  ) : (
                    ''
                  )} */}
                <Data />

                <MDBNavbarItem>
                  {rolePermissions.Report &&
                  Object.values(rolePermissions?.Report).includes("1") ? (
                    <MDBDropdown>
                      <MDBDropdownToggle tag="a" className="nav-link">
                        Reports
                      </MDBDropdownToggle>

                      <MDBDropdownMenu>
                        {rolePermissions?.Report?.Leave_Report == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/leaves-report">
                              <MDBDropdownLink>Leave Report</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.Report?.Ticket_Report == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/tickets-report">
                              <MDBDropdownLink>Ticket Report</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.Report?.Face_Report == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/report/attendance">
                              <MDBDropdownLink>Face Report</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {rolePermissions?.Report?.Manual_Attendance_Request ==
                        "1" ? (
                          <MDBDropdownItem>
                            <Link to="/checkInRequest">
                              <MDBDropdownLink>
                                Attendance Request
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.Report?.Attendance_Summary == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/report/attendance-summary">
                              <MDBDropdownLink>
                                Attendance Summary
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.Report?.Attendance_Summary == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/report/kra">
                              <MDBDropdownLink>KRA</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.Report?.Attendance_Summary == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/EmployeeWorkingHours">
                              <MDBDropdownLink>
                                Employee Working Hours
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  ) : (
                    ""
                  )}
                </MDBNavbarItem>

                <MDBNavbarItem>
                  {rolePermissions?.PM_Report &&
                  Object.values(rolePermissions?.PM_Report).includes("1") ? (
                    <MDBDropdown>
                      <MDBDropdownToggle tag="a" className="nav-link">
                        PM Reports
                      </MDBDropdownToggle>

                      <MDBDropdownMenu>
                        {rolePermissions?.PM_Report?.Employee_Tracker == "1" ? (
                          <MDBDropdownItem>
                            <MDBNavbarItem>
                              <Link to="/employee-tracker">
                                <MDBNavbarLink>Employee Tracker</MDBNavbarLink>
                              </Link>
                            </MDBNavbarItem>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.PM_Report?.Project_Working_Hours ==
                        "1" ? (
                          <MDBDropdownItem>
                            <MDBNavbarItem>
                              <Link to="/Project-Working-Hours">
                                <MDBNavbarLink>
                                  Project-Working-Hours
                                </MDBNavbarLink>
                              </Link>
                            </MDBNavbarItem>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {rolePermissions?.PM_Report?.Seven_Days_Working_plan ==
                        "1" ? (
                          <MDBDropdownItem>
                            <MDBNavbarItem>
                              <Link to="/employeeWorkingPlan">
                                <MDBNavbarLink>
                                  7 Days Working plan
                                </MDBNavbarLink>
                              </Link>
                            </MDBNavbarItem>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {rolePermissions?.PM_Report?.Milestone_Report == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/milestone/report">
                              <MDBDropdownLink>
                                Milestone Report
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {rolePermissions?.PM_Report?.Milestone_Report == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/milestonesList">
                              <MDBDropdownLink>
                                Milestone Report2
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {rolePermissions?.PM_Report?.Milestone_Report == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/storyList">
                              <MDBDropdownLink>Story Report</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {rolePermissions?.PM_Report?.Project_Status_Report ==
                        "1" ? (
                          <MDBDropdownItem>
                            <Link to="/report/project-status">
                              <MDBDropdownLink>
                                Project Status Report
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.PM_Report?.Sprint_Report == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/sprint/report">
                              <MDBDropdownLink>Sprint Report</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.PM_Report?.Task_Report == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/reportList">
                              <MDBDropdownLink>Task Report</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.PM_Report?.Task_Timer_Report ==
                        "1" ? (
                          <MDBDropdownItem>
                            <Link to="/tasks/tasktimer/report">
                              <MDBDropdownLink>
                                Task Timer Report
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {rolePermissions?.PM_Report?.Dues == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/dueDashboard">
                              <MDBDropdownLink>Dues</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.PM_Report?.Task_Timer_Report ==
                        "1" ? (
                          <MDBDropdownItem>
                            <Link to="/add-team">
                              <MDBDropdownLink>Clusters</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {/* {rolePermissions?.PM_Report?.Story_Tracker == "1" ? ( */}
                        <MDBDropdownItem>
                          <MDBNavbarItem>
                            <Link to="/story-tracker">
                              <MDBNavbarLink>Story Tracker</MDBNavbarLink>
                            </Link>
                          </MDBNavbarItem>
                        </MDBDropdownItem>
                        {/* ) : (
                          ""
                        )} */}

                        {rolePermissions?.PM_Report?.Employee_Hours == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/employee-hours">
                              <MDBDropdownLink>
                                Employee Hours List
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  ) : (
                    ""
                  )}
                </MDBNavbarItem>
                {/* ) : (
                      ""
                    )
                  ) : (
                    ""
                  )} */}

                <MDBNavbarItem>
                  {rolePermissions?.Master &&
                  Object.values(rolePermissions?.Master).includes("1") ? (
                    <MDBDropdown>
                      <MDBDropdownToggle tag="a" className="nav-link">
                        Master
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        {rolePermissions?.Master?.Position == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/positions">
                              <MDBDropdownLink>Position</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        <MDBDropdownItem>
                          <Link to="/roles-permission">
                            <MDBDropdownLink>Roles Permission</MDBDropdownLink>
                          </Link>
                        </MDBDropdownItem>
                        {rolePermissions?.Master?.Department == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/department">
                              <MDBDropdownLink>Department</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.Master?.Holidays == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/Holidays">
                              <MDBDropdownLink>Holidays</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.Master?.Ticket_Department == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/ticket-department">
                              <MDBDropdownLink>
                                Ticket Department
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.Master?.Location == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/location">
                              <MDBDropdownLink>Location</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {rolePermissions?.Master?.Vertical == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/vertical">
                              <MDBDropdownLink>Vertical</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {rolePermissions?.Master?.Attendance_Device == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/attendance-device">
                              <MDBDropdownLink>
                                Attendance Device
                              </MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {rolePermissions?.Master?.Configuration == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/configuration">
                              <MDBDropdownLink>Configuration</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {rolePermissions?.Master?.EscalationType == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/escalationType">
                              <MDBDropdownLink>Escalation Type</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}
                        {rolePermissions?.Master?.ShiftMaster == "1" ? (
                          <MDBDropdownItem>
                            <Link to="/shiftMaster">
                              <MDBDropdownLink>Shift Master</MDBDropdownLink>
                            </Link>
                          </MDBDropdownItem>
                        ) : (
                          ""
                        )}

                        {/* <MDBDropdownItem>
                          <Link to="/event-dashboard">
                            <MDBDropdownLink>Events</MDBDropdownLink>
                          </Link>
                        </MDBDropdownItem> */}
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  ) : (
                    ""
                  )}
                </MDBNavbarItem>
              </>
            </MDBNavbarNav>
          </MDBCollapse>
          {/* 
          <Button className="header-leave-btn" onClick={() => setVisible(true)}>
            Attendance Confirmation
          </Button> */}

          <Modal
            title="Please confirm your leave?"
            centered
            // visible={visible}
            open={visible}
            onOk={() => setVisible(false)}
            onCancel={handleCancel}
            width={700}
            footer={null}
          >
            <Table
              size="small"
              dataSource={dataSource}
              pagination={false}
              columns={columns}
              showHeader={false}
            />

            <Button
              className="pms-same-btn"
              type="success"
              onClick={() => {
                UpdateStatus("Approved");
                setVisible(false);
              }}
              style={{
                marginRight: "10px",
                marginTop: "20px",
                backgroundColor: "green",
                color: "white",
              }}
            >
              Confirm
            </Button>
            <Button
              className="pms-same-btn-cancel"
              type="danger"
              onClick={() => {
                UpdateStatus("Issue");
                setVisible(false);
              }}
              style={{
                marginRight: "10px",
                marginTop: "20px",
              }}
            >
              Issue
            </Button>
          </Modal>
          <div className="marquee-main-box">
            {res.slice(0, 3).includes(currDay) ? (
              // <Marquee className="sub-mar-txt">
              //   "Fill KRA before 3{" "}
              //   <span>&nbsp; {moment().format("MMM")} &nbsp;</span>{" "}
              //   {moment().format("YYYY")}
              // </Marquee>
              <Marquee className="sub-mar-txt">
                {"Fill KRA before "}
                <span>&nbsp;{getNextWorkingDay()}&nbsp;</span>
                {moment().format("MMM")} &nbsp;{moment().format("YYYY")}
              </Marquee>
            ) : (
              ""
            )}{" "}
          </div>
          {/* New */}
          {/* <div className="marquee-main-box">
            {attendanaceMarqueeVisible ? (
              <Marquee className="sub-mar-txt">
                Please Approve/Reject for attendance (
                {`${previousMonth} - ${year}`}) &nbsp;
              </Marquee>
            ) : (
              ""
            )}
          </div> */}

          <Checkinout {...props} Working_HOURS={totalWorkingHOurs} />

          {/* <div className="head-drop-div-main">
            <Dropdown
              className="head-mobile-drop"
              menu={{
                items,
              }}
              onClick={addClass}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <DropboxOutlined />
                </Space>
              </a>
            </Dropdown>
          </div> */}

          <MDBNavbarItem className="nav-bar-user-name">
            <MDBDropdown>
              <MDBDropdownToggle tag="a" className="nav-link user-name">
                {userData[0]?.First_name} &nbsp;
                {userData[0]?.Last_name}
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                <MDBDropdownItem>
                  <Link to="/profile">
                    <MDBDropdownLink>Profile</MDBDropdownLink>
                  </Link>

                  {/* <Link onClick={() => logout()}>
                    <MDBDropdownLink>Logout</MDBDropdownLink>
                  </Link> */}
                  <MDBDropdownLink onClick={() => logout()}>
                    Logout
                  </MDBDropdownLink>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarItem>

          <MDBNavbarBrand href="#" className="profile">
            <img
              className="profile-set"
              src={
                profilePic
                  ? imageUrl + profilePic
                  : "https://joeschmoe.io/api/v1/male/random"
              }
            ></img>
          </MDBNavbarBrand>

          <button type="primary" className="mobile-menu" onClick={showDrawer}>
            <MenuOutlined />
          </button>
          <Drawer
            title="main-drawer"
            placement={"left"}
            closable={false}
            onClose={onClose}
            open={open}
            // visible={open}
            key={placement}
            className="main-drawer"
          >
            <MDBListGroup className="list-group-flush list-group-item-1 drawer-list">
              <NavLink exact={true} to="/" activeClassName="activeClass">
                <MDBListGroupItem onClick={() => onClose()}>
                  <MDBIcon icon="home" className="mr-3 menu-icon" />
                  Dashboard
                </MDBListGroupItem>
              </NavLink>

              <NavLink to="/projects" activeClassNam e="activeClass">
                <MDBListGroupItem onClick={() => onClose()}>
                  <MDBIcon icon="briefcase" className="mr-3 menu-icon " />
                  Project
                </MDBListGroupItem>
              </NavLink>

              <NavLink to="/me" activeClassName="activeClass">
                <MDBListGroupItem onClick={() => onClose()}>
                  <MDBIcon icon="user" className="mr-3 menu-icon " />
                  Me
                </MDBListGroupItem>
              </NavLink>

              <NavLink to="/directory" activeClassName="activeClass">
                <MDBListGroupItem onClick={() => onClose()}>
                  <MDBIcon icon="address-book" className="mr-3 menu-icon " />
                  Directory
                </MDBListGroupItem>
              </NavLink>

              <NavLink to="/tickets" activeClassName="activeClass">
                <MDBListGroupItem onClick={() => onClose()}>
                  <MDBIcon icon="ticket-alt" className="mr-3 menu-icon " />
                  Tickets
                </MDBListGroupItem>
              </NavLink>
              <NavLink to="/vh-policy" activeClassName="activeClass">
                <MDBListGroupItem
                  className={location.pathname == "/vh-policy" ? "active" : ""}
                >
                  <MDBIcon icon="address-book" className="mr-3 menu-icon " />
                  VH Policy
                </MDBListGroupItem>
              </NavLink>
              <NavLink to="/escalation" activeClassName="activeClass">
                <MDBListGroupItem
                  className={location.pathname == "/escalation" ? "active" : ""}
                >
                  <MDBIcon icon="address-book" className="mr-3 menu-icon " />
                  Escalation
                </MDBListGroupItem>
              </NavLink>
              {rolePermissions?.KRA?.Employee_KRA == "1" ? (
                <>
                  {res.slice(0, 5).includes(currDay) ? (
                    <>
                      <NavLink to="/KRA" activeClassName="activeClass">
                        <MDBListGroupItem onClick={() => onClose()}>
                          <MDBIcon icon="file" className="mr-3 menu-icon " />
                          KRA
                        </MDBListGroupItem>
                      </NavLink>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
              {rolePermissions?.KRA?.TL_HOD_KRA == "1" ? (
                <>
                  {res.slice(0, 5).includes(currDay) ? (
                    <NavLink to="/TLKRA" activeClassName="activeClass">
                      <MDBListGroupItem onClick={() => onClose()}>
                        <MDBIcon icon="file" className="mr-3 menu-icon " />
                        TL/HOD KRA
                      </MDBListGroupItem>
                    </NavLink>
                  ) : (
                    ""
                  )}{" "}
                </>
              ) : (
                ""
              )}

              {/* <NavLink to="/employees" activeClassName="activeClass">
                <MDBListGroupItem>
                  <MDBIcon icon="" className="mr-3 menu-icon " />
                  Employee
                </MDBListGroupItem>
              </NavLink> */}

              {/* 
              <Menu
                className="report-mobile"
                onClick={onClick}
                style={{
                  width: 256,
                }}
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={[""]}
                mode="inline"
                items={items}
              /> */}

              {/* <Menu
                className="report-mobile"
                onClick={onClick}
                style={{
                  width: 256,
                }}
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={[""]}
                mode="inline"
                items={items2}
              /> */}

              {/* <Dropdown overlay={menu} className="master-dropdown">
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    Master
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown> */}
            </MDBListGroup>
          </Drawer>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
}
