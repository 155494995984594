import React, { useEffect, useState } from "react";
import { MDBCard, MDBCol } from "mdb-react-ui-kit";
import moment from "moment";
import { Button, Input, Modal, Select } from "antd";
import ReactLoader from "../../../components/common/ReactLoader";
import PmsRangePicker from "../me/leaveModule/RangePicker";
import AxiosInstance from "../../../service/AxiosInstance";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

const { Option } = Select;

function EmployeeHoursList() {
  const [employeeHoursList, setEmployeeHoursList] = useState([]);
  const [datatable, setDatatable] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [username, setUsername] = useState("");
  const [isModalTaskList, setIsModalTaskList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getEmployeeHoursList = () => {
    const formData = new URLSearchParams();
    formData.append("startDate", dateRange?.[0] || "");
    formData.append("endDate", dateRange?.[1] || "");
    formData.append("employeeId", username || "");
    formData.append("page", page + 1 || "");
    formData.append("limit", rowsPerPage || "");

    setLoading(true);
    AxiosInstance.post("/auth/emaplyee/hours-list", formData)
      .then((res) => {
        setLoading(false);
        if (res?.data?.status === 1) {
          setEmployeeHoursList(res?.data?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    const transformedData = employeeHoursList?.map((employee, index) => {
      const projectsList = employee?.projects?.map((project) => (
        <div className="project-name-task-hours" key={project.Project_name}>
          {project.Project_name}
        </div>
      ));

      const projectTotalTaskHours = employee?.projects?.map(
        (project, projectIndex) => (
          <div
            key={projectIndex}
            className="project-name-task-hours"
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => showTaskListModal(project.tasks)}
          >
            {project.projectTaskHours}
          </div>
        )
      );

      return {
        id: index + 1,
        Employee_name: employee.Employee_name,
        Project_name: projectsList,
        Hours: projectTotalTaskHours,
        tasks: employee.projects.flatMap((project) => project.tasks),
      };
    });

    setDatatable(transformedData);
  }, [employeeHoursList]);

  const getEmployeeList = () => {
    AxiosInstance.get("auth/employees").then((res) => {
      if (res?.data?.status === 1) {
        setOptions(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    getEmployeeList();
  }, []);

  useEffect(() => {
    getEmployeeHoursList();
  }, [rowsPerPage, page]);

  const showTaskListModal = (tasks) => {
    setIsModalTaskList(tasks);
    setIsModalVisible(true);
  };

  const handleTaskListCancel = () => {
    setIsModalVisible(false);
    setIsModalTaskList([]);
    setSearchQuery("");
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    getEmployeeHoursList();
  };

  const filteredTasks = isModalTaskList.filter(
    (task) =>
      task?.Project_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      task?.Task_key.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="demo employee-hours-list-cus">
      <MDBCard className="page-content">
        <div className="create-project">
          <MDBCol size="md" className="col-example titel-set">
            <h3 className="tablHeader">Employee Hours List</h3>
          </MDBCol>
        </div>
        <div className="row">
          <MDBCol size="md-3">
            <label className="start-list">Due Date</label>
            <PmsRangePicker
              className="search-rangepicker"
              allowClear={true}
              format="DD-MM-YYYY"
              onChange={(values) => {
                setDateRange(
                  values
                    ? values.map((item) => moment(item).format("MM/DD/YYYY"))
                    : []
                );
              }}
            />
          </MDBCol>
          <MDBCol size="md-3">
            <label className="start-list">Employee</label>
            <Select
              placeholder="Employee Search"
              allowClear={true}
              showSearch
              className="select-dropdown leave-4"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              onChange={setUsername}
            >
              {options?.map((option) => (
                <Option key={option?._id} value={option?._id}>
                  {`${option?.First_name} ${option?.Last_name}`}
                </Option>
              ))}
            </Select>
          </MDBCol>
          <MDBCol size="md-3">
            <div className="employee-search-btn">
              <Button
                // className="search-button-empl-hours"
                className="pms-same-btn search-button-empl-hours"
                onClick={handleSearch}
                style={{ marginTop: "10px" }}
              >
                Search
              </Button>
            </div>
          </MDBCol>
        </div>

        {loading ? (
          <ReactLoader loading={loading} />
        ) : (
          <TableContainer>
            <Table className="employee-hours-table mdb-dataTable">
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Employee Name</TableCell>
                  <TableCell>Project Name</TableCell>
                  <TableCell>Hours</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datatable.length > 0 ? (
                  datatable?.map((row) => (
                    <TableRow key={row?.id}>
                      <TableCell>{row?.id}</TableCell>
                      <TableCell>{row?.Employee_name}</TableCell>
                      <TableCell>
                        <div>{row?.Project_name}</div>
                      </TableCell>
                      <TableCell>
                        <div>{row?.Hours}</div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow className="no-data">
                    <TableCell colSpan={4}>No data found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            <TablePagination
              className="total-task-details-pag"
              rowsPerPageOptions={[10, 20, 25]}
              component="div"
              count={datatable.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
          </TableContainer>
        )}
      </MDBCard>

      <Modal
        width={800}
        title={"Employee Task List"}
        visible={isModalVisible}
        onCancel={handleTaskListCancel}
        footer={null}
      >
        <div>
          <Input
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ marginBottom: "10px", width: "200px" }}
          />
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Project Name</th>
                <th>Task Key</th>
                <th>Issue Type</th>
                <th>Status</th>
                <th>Task Duration</th>
              </tr>
            </thead>
            <tbody>
              {filteredTasks?.map((task, index) => (
                <tr key={index}>
                  <td>{task?.Project_name}</td>
                  <td>{task?.Task_key}</td>
                  <td>{task?.Issue_type}</td>
                  <td>{task?.Status}</td>
                  <td>{task?.TaskRunningTime}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
}

export default EmployeeHoursList;
