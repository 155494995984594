import React from "react";
import AttendanceReport from "../container/pages/attendance/AttendanceReport";
import ManualAttendanceReport from "../container/pages/attendance/ManualAttendanceReport";
import AttendanceSummary from "../container/pages/attendance/AttendanceSummary";
import MonthWiseAttendanceReport from "../container/pages/attendance/MonthWiseAttendanceReport";
import DueDashboard from "../container/pages/dueDashboard/DueDashboard";
import EmployeeTracker from "../container/pages/employeeTracker/EmployeeTracker";
import ProjectWorkingHours from "../container/pages/projectWorkingHours/ProjectWorkingHours";
import EmployeePresentReport from "../container/pages/employee_master/EmployeePresentReport";
import Location from "../container/pages/location/Location";
import Attendance from "../container/pages/me/attendance/Attendance";
import Me from "../container/pages/me/Me";
import Overtime from "../container/pages/me/overtime/Overtime";
import MilestoneReport from "../container/pages/projects/milestone/MilestoneReport";
import SprintReport from "../container/pages/projects/sprint/SprintReport";
import ProjectStatusReport from "../container/pages/projectStatusReport/ProjectStatusReport";
import TaskTimerReport from "../container/pages/tasks/TaskTimerReport";
import { Vertical } from "../container/pages/vertical/Vertical";
import KRA from "../container/pages/employeeKra/KRA";
import EmployeeWorkingHours from "../container/pages/employeeWorkingHours/EmployeeWorkingHours";
import TLKRA from "../container/pages/employeeKra/TLKra";
import Resource from "../container/pages/projects/Resource";
import AllocatedResource from "../container/pages/projects/AllocatedResource";
import RequestedResource from "../container/pages/projects/RequestedResource";
import ContactUs from "../container/pages/vhPolicy/ContactUs.js";
import View from "../container/pages/tasks/View.js";
import { ProjectStatus } from "../container/pages/Options";
import TaskTable from "../container/pages/eventdashboard/MyTeam/MyTeamTask";
import { Loading } from "../components/common/Loader";
import KraForm from "../container/pages/hrDashboard/Kra";
import TLViewKra from "../container/pages/employeeKra/TLViewKra";
import ResourceManager from "../container/pages/resourceManager/ResourceManager";
import PmDashboard from "../container/pages/eventdashboard/pmDashboard/PmDashboard";
import OtherDetails from "../container/pages/employee_master/OtherDetails";
import TeamsLeaves from "../container/pages/teamMembers/TeamsLeave.js";
import AddTeamMember from "../container/pages/teamMembers/AddTeamMember.js";
import LeavePolicy from "../container/pages/vhPolicy/LeavePolicy.js";
import HolidaysCalendar from "../container/pages/vhPolicy/HolidaysCalendar.js";
import ManualCheckOutRequest from "../container/pages/attendance/manualAttendanceTabs/ManualCheckOutRequest.js";
import ManualCheckInRequest from "../container/pages/attendance/manualAttendanceTabs/ManualCheckInRequest.js";
import MilestonesList from "../container/pages/projects/milestone/MilestonesList.js";
import MilestoneList2 from "../container/pages/projects/milestone/MilestoneList2.js";
import StoryList from "../container/pages/projects/story/StoryReport.js";
import StoryReport2 from "../container/pages/projects/story/StoryReport2.js";
import TeamKra from "../container/pages/teamMembers/TeamKra.js";
import StoryTracker from "../container/pages/storyTracker/StoryTracker.js";
import AttendanceDevice from "../container/pages/master/attendanceDevice/AttendanceDevice.js";
import MailSetting from "../container/pages/master/mailSetting/MailSetting.js";
import EscalationType from "../container/pages/master/escalationType/EscalationType.js";
import EscalationList from "../container/pages/escalation/EscalationList.js";
import ViewEscalation from "../container/pages/escalation/ViewEscalation.js";
import TaskDashboard from "../container/pages/taskDashboard/TaskDashboard.js";
import EmployeeHoursList from "../container/pages/employeeHoursList/EmployeeHoursList.js";
import AttendanceImage from "../container/pages/attendance/AttendanceImage.js";
import ShiftMasterList from "../container/pages/master/shiftMaster/ShiftMasterList.js";

let userData = localStorage.getItem("userdata")
  ? localStorage.getItem("userdata")
  : undefined;
let Position_Id = userData ? JSON.parse(userData)[0].Position_id : undefined;

const Dashboard = React.lazy(() =>
  import("../container/pages/eventdashboard/Eventdashboard")
);
const Mydashboard = React.lazy(() =>
  import("../container/pages/eventdashboard/Mydashboard")
);
const EmployeeReport = React.lazy(() =>
  import("../container/pages/employeeReport/EmployeeReport")
);
const EmployeeChart = React.lazy(() =>
  import("../container/pages/employeeChart/EmployeeChart")
);

const TaskProgress = React.lazy(() =>
  import("../container/pages/taskProgress/TaskProgress")
);
const MyAttendance = React.lazy(() =>
  import("../container/pages/attendance/MyAttendance")
);
const EmployeePresentList = React.lazy(() =>
  import("../container/pages/attendance/EmployeePresentList")
);

const TeamLeave = React.lazy(() =>
  import("../container/pages/eventdashboard/teamLeave/TeamLeave")
);
const MyDailyLogs = React.lazy(() =>
  import("../container/pages/me/myDailyLogs/MyDailyLogs")
);

const ProjectTotalHours = React.lazy(() =>
  import("../container/pages/projects/ProjectTotalHours/ProjectTotalHours")
);
const Pending = React.lazy(() =>
  import("../container/pages/eventdashboard/teamLeave/Pending")
);
const InterviewForm = React.lazy(() =>
  import("../container/pages/forms/interviewForm/InterviewForm")
);
const HrDashboard = React.lazy(() =>
  import("../container/pages/hrDashboard/HrDashboard")
);
// leaves
const Leaves = React.lazy(() =>
  import("../container/pages/me/leaveModule/List")
);
const LeavesReport = React.lazy(() =>
  import("../container/pages/me/leaveModule/ListReport")
);
const QuarterlyLeave = React.lazy(() =>
  import("../container/pages/me/leaveModule/QuarterlyLeave")
);
const AnnualLeave = React.lazy(() =>
  import("../container/pages/me/leaveModule/AnnualLeave")
);

const CPOLeaveReport = React.lazy(() =>
  import("../container/pages/me/leaveModule/CPOLeaveReport.js")
);

const EmergencyListReport = React.lazy(() =>
  import("../container/pages/me/leaveModule/EmergencyListReport")
);

const ForgotPass = React.lazy(() =>
  import("../container/pages/password/ForgotPass")
);

// leaves
const Tickets = React.lazy(() => import("../container/pages/ticket/List"));
const TicketsReport = React.lazy(() =>
  import("../container/pages/ticket/ListReport")
);
//
//#region project
const ProjectAdd = React.lazy(() => import("../container/pages/projects/Add"));
const ProjectList = React.lazy(() =>
  import("../container/pages/projects/List")
);
const ProjectDetail = React.lazy(() =>
  import("../container/pages/projects/Detail")
);
const ProjectTaskDetail = React.lazy(() =>
  import("../container/pages/projects/TaskDetails")
);
const projectMember = React.lazy(() =>
  import("../container/pages/projects/Members")
);
const Milestone = React.lazy(() =>
  import("../container/pages/projects/milestone/Milestone")
);
const Sprint = React.lazy(() =>
  import("../container/pages/projects/sprint/Sprint")
);

//#endregion project

const DirectoryList = React.lazy(() =>
  import("../container/pages/directory/DirectoryList")
);

const ProfileDetails = React.lazy(() =>
  import("../container/pages/profile/ProfileDetails")
);

//#region Employee
const EmployeeList = React.lazy(() =>
  import("../container/pages/employee_master/EmployeeList")
);
const EmployeeDetail = React.lazy(() =>
  import("../container/pages/employee_master/EmployeeDetail")
);
//#endregion

//#region Task
const TaskList = React.lazy(() => import("../container/pages/tasks/List"));
const ProjectTaskList = React.lazy(() =>
  import("../container/pages/projects/TaskList")
);
const TaskView = React.lazy(() => import("../container/pages/tasks/View.js"));

const BugList = React.lazy(() =>
  import("../container/pages/tasks/Bug/BugLIst")
);
//#endregion

//#region master position
const Position = React.lazy(() => import("../container/pages/master/Position"));
const Roles_Permission = React.lazy(() =>
  import("../container/pages/master/rolesPermission/RolesPermission")
);
const addRolespermission = React.lazy(() =>
  import("../container/pages/master/rolesPermission/AddRolespermission")
);
const ViewRolesPermission = React.lazy(() =>
  import("../container/pages/master/rolesPermission/ViewRolesPermission")
);

const EditrolesPermission = React.lazy(() =>
  import("../container/pages/master/rolesPermission/EditrolesPermission")
);
const ClientRequirements = React.lazy(() =>
  import("../container/pages/master/ClientRequirements")
);
const TechStack = React.lazy(() =>
  import("../container/pages/master/TechStack")
);
const Department = React.lazy(() =>
  import("../container/pages/master/Department")
);
const TicketDepartment = React.lazy(() =>
  import("../container/pages/master/TicketDepartment")
);
const Holidays = React.lazy(() =>
  import("../container/pages/master/Holidays/Holidays")
);
//#endregion

//#region Report
const Report = React.lazy(() => import("../container/pages/Report"));
//#endregion

//eventdashboard
// const Eventdashboard = React.lazy(() => import('../container/pages/eventdashboard/eventdashboard'))

// viewall
const HolidaysList = React.lazy(() =>
  import("../container/pages/holidaysList/HolidaysList")
);

const Backlog = React.lazy(() =>
  import("../container/pages/projects/Backlog.js")
);

export const Routes = [
  {
    path: "/vh-policy",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/escalation",
    name: "Escalation",
    component: EscalationList,
  },

  {
    path: "/Hr-handbook",
    name: "LeavePolicy",
    component: LeavePolicy,
  },
  {
    path: "/holidays-calendar",
    name: "HolidaysCalendar",
    component: HolidaysCalendar,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
  },
  // pending
  {
    path: "/interviewform",
    name: "interviewform",
    component: InterviewForm,
  },
  {
    path: "/hr-dashboard",
    name: "hrdashboard",
    component: HrDashboard,
    objectkey: "Dashboard",
    permissionKey: "HR_Dashboard",
  },
  {
    path: "/my-dashboard",
    name: "mydashboard",
    component: Mydashboard,
    // objectkey:'Dashboard',
    // permissionKey:'MY_Dashboard'
  },
  {
    path: "/pm-dashboard",
    name: "pmdashboard",
    component: PmDashboard,
    objectkey: "Dashboard",
    permissionKey: "PM_TPM_Dashboard",
  },
  {
    path: "/task-dashboard",
    name: "taskdashboard",
    component: TaskDashboard,
    objectkey: "Dashboard",
    permissionKey: "Task_Dashboard",
  },
  {
    path: "/resource-manager",
    name: "resourcemanager",
    component: ResourceManager,
    objectkey: "Dashboard",
    permissionKey: "Resource_Manager_Dashboard",
  },
  // pending
  {
    path: "/employeereport",
    name: "employeereport",
    component: EmployeeReport,
  },
  {
    path: "/project/employeechart/:id",
    name: "employeechart",
    component: EmployeeChart,
  },
  {
    path: "/taskprogress",
    name: "taskprogress",
    component: TaskProgress,
  },
  {
    path: "/myattendance",
    name: "myattendance",
    component: MyAttendance,
  },
  {
    path: "/employeepresentlist",
    name: "employeepresentlist",
    component: EmployeePresentList,
  },
  {
    path: "/employeeWorkingPlan",
    name: "employeeworkingplan",
    component: EmployeeReport,
    objectkey: "PM_Report",
    permissionKey: "Seven_Days_Working_plan",
  },
  {
    path: "/teamleave",
    name: "teamleave",
    component: TeamLeave,
    objectkey: "Me",
    permissionKey: "Team_Leave",
  },
  {
    path: "/mydailylogs",
    name: "mydailylogs",
    component: MyDailyLogs,
  },
  {
    path: "/ProjectTotalHours",
    name: "ProjectTotalHours",
    component: ProjectTotalHours,
  },
  {
    path: "/pending",
    name: "pending",
    component: Pending,
  },
  //#region project routes
  {
    path: "/",
    exact: true,
  },
  {
    path: "/PMS",
    name: "PMS",
    component: ProjectStatus,
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileDetails,
  },

  {
    path: "/projects",
    name: "projectList",
    component: ProjectList,
  },
  {
    path: "/project/tasks/:id",
    name: "projectList",
    component: ProjectTaskList,
    sidebar: false,
    objectkey: "Project",
    permissionKey: "View_Project",
  },
  {
    path: "/project/backlog/:id",
    name: "backlog",
    component: Backlog,
  },
  {
    path: "/project/member/:id",
    name: "projectMember",
    component: projectMember,
  },
  {
    path: "/project/resource/:id",
    name: "resource",
    component: Resource,
    objectkey: "Project",
    permissionKey: "View_Member",
  },
  {
    path: "/project/allocatedresources/:id",
    name: "allocatedresources",
    component: AllocatedResource,
  },
  {
    path: "/project/requestedresources/:id",
    name: "requestedresources",
    component: RequestedResource,
  },
  {
    path: "/project/sprint/:id",
    name: "Sprint",
    component: Sprint,
    objectkey: "Sprint_Project",
    permissionKey: "List",
  },
  {
    path: "/project/milestone/:id",
    name: "Milestone",
    component: Milestone,
    objectkey: "Milestone_Project",
    permissionKey: "List",
  },
  {
    path: "/project/task/:id/:projectid",
    name: "projectTask",
    component: ProjectTaskDetail,
  },
  {
    path: "/projects/add",
    name: "projectAdd",
    component: ProjectAdd,
  },
  {
    path: "/project/view/:id",
    name: "projectView",
    component: ProjectDetail,
  },

  //#endregion project routes

  //#region task routes
  {
    exact: true,
    path: "/directory",
    name: "employees",
    component: DirectoryList,
  },
  {
    exact: true,
    path: "/report/attendance",
    name: "attendanace",
    component: AttendanceReport,
    objectkey: "Report",
    permissionKey: "Face_Report",
  },
  {
    exact: true,
    path: "/report/attendance/image",
    name: "attendanaceImage",
    component: AttendanceImage,
    objectkey: "Report",
    permissionKey: "Face_Report",
  },
  {
    exact: true,
    path: "/attendance",
    name: "attendanace",
    component: Attendance,
  },
  {
    exact: true,
    path: "/attendanceSummary",
    name: "attendanceSummary",
    component: AttendanceSummary,
  },
  {
    exact: true,
    path: "/report/month-wise-attendance",
    name: "attendanace",
    component: MonthWiseAttendanceReport,
  },
  {
    exact: true,
    path: "/report/attendance-summary",
    name: "attendance-report",
    component: AttendanceSummary,
    objectkey: "Report",
    permissionKey: "Attendance_Summary",
  },
  {
    exact: true,
    path: "/report/kra",
    name: "kra",
    component: KraForm,
    objectkey: "Report",
    permissionKey: "KRA",
  },

  {
    exact: true,
    path: "/report/project-status",
    name: "attendanace",
    component: ProjectStatusReport,
    objectkey: "PM_Report",
    permissionKey: "Project_Status_Report",
  },

  {
    exact: true,
    path: "/employees",
    name: "employees",
    component: EmployeeList,
    objectkey: "Employee",
    permissionKey: "View",
  },
  {
    exact: true,
    path: "/taskList",
    name: "taskList",
    component: TaskList,
  },
  {
    exact: true,
    path: "/task/:id",
    name: "taskView",
    component: TaskView,
  },
  {
    exact: true,
    path: "/task/:id/BugList",
    name: "BugList",
    component: BugList,
  },
  //#endregion
  {
    path: "/employeeDetail/:id",
    name: "employeeDetail",
    component: EmployeeDetail,
    objectkey: "Employee",
    permissionKey: "View",
  },
  {
    path: "/employeeOtherDetail/:id",
    name: "otherDetail",
    component: OtherDetails,
    objectkey: "Employee",
    permissionKey: "View",
  },
  {
    path: "/employeeAttendance/:id",
    name: "employeeAttendance",
    component: MyAttendance,
  },
  {
    path: "/employeeDailyLogs/:id",
    name: "employeeDailylogs",
    component: MyDailyLogs,
  },
  //#endregion employee routes

  //#region master position
  {
    path: "/positions",
    name: "position",
    component: Position,
    objectkey: "Master",
    permissionKey: "Position",
  },
  {
    path: "/roles-permission",
    name: "Roles Permission",
    component: Roles_Permission,
    objectkey: "Master",
    permissionKey: "Roles_Permission",
  },
  {
    path: "/add-rolesPermission",
    name: "Roles Permission Add",
    component: addRolespermission,
    objectkey: "Master",
    permissionKey: "Roles_Permission",
  },
  {
    path: "/View-rolesPermission/:id",
    name: "Roles Permission View",
    component: ViewRolesPermission,
    objectkey: "Master",
    permissionKey: "Roles_Permission",
  },
  {
    path: "/View-escalation/:id",
    name: "Escalation View",
    component: ViewEscalation,
  },
  {
    path: "/editrolespermission/:id",
    name: "Edit RolesPermission",
    component: EditrolesPermission,
    objectkey: "Master",
    permissionKey: "Roles_Permission",
  },
  {
    path: "/requirements",
    name: "requirements",
    component: ClientRequirements,
  },
  {
    path: "/techStack",
    name: "techStack",
    component: TechStack,
  },
  {
    path: "/department",
    name: "department",
    component: Department,
    objectkey: "Master",
    permissionKey: "Department",
  },
  {
    path: "/ticket-department",
    name: "ticketdepartment",
    component: TicketDepartment,
    objectkey: "Master",
    permissionKey: "Ticket_Department",
  },

  {
    path: "/holidays",
    name: "Holidays",
    component: Holidays,
    objectkey: "Master",
    permissionKey: "Holidays",
  },
  //#endregion
  //#region report
  {
    PositionId: Position_Id,
    path: "/reportList",
    name: "reportList",
    component: Report,
    exact: true,
    objectkey: "PM_Report",
    permissionKey: "Task_Report",
  },
  //region leaves
  {
    path: "/leaves",
    name: "leaves",
    component: Leaves,
  },
  {
    path: "/leaves-report",
    name: "leaves",
    component: LeavesReport,
    objectkey: "Report",
    permissionKey: "Leave_Report",
  },
  //   {
  // path:"/emergencyrequest-report",
  // name:"leaves",
  // component : EmergencyListReport
  //   },
  {
    path: "/quarterly-leave",
    name: "QuarterlyLeave",
    component: QuarterlyLeave,
  },
  {
    path: "/annual-leave",
    name: "AnnualLeave",
    component: AnnualLeave,
  },
  {
    path: "/cpo-leave",
    name: "CpoLeaveReport",
    component: CPOLeaveReport,
  },
  //region tickes
  {
    path: "/tickets",
    name: "tickets",
    component: Tickets,
  },
  {
    path: "/tickets-report",
    name: "tickets",
    component: TicketsReport,
    objectkey: "Report",
    permissionKey: "Ticket_Report",
  },

  {
    path: "/forgot-password",
    name: "forgotpassword",
    component: ForgotPass,
  },
  // {
  //   path: '/event-dashboard',
  //   name: 'eventdashboard',
  //   component: Eventdashboard,
  // },
  {
    path: "/holidays-list",
    name: "HolidaysList",
    component: HolidaysList,
  },

  {
    path: "/me",
    name: "me",
    component: Me,
  },
  {
    path: "/milestone/report",
    name: "MilestoneReport",
    component: MilestoneReport,
    objectkey: "PM_Report",
    permissionKey: "Milestone_Report",
  },
  {
    path: "/sprint/report",
    name: "SprintReport",
    component: SprintReport,
    objectkey: "PM_Report",
    permissionKey: "Sprint_Report",
  },
  {
    path: "/tasks/tasktimer/report",
    name: "tasktimerReport",
    component: TaskTimerReport,
    objectkey: "PM_Report",
    permissionKey: "Task_Timer_Report",
  },
  {
    path: "/employee-hours",
    name: "employeeHoursList",
    component: EmployeeHoursList,
    objectkey: "PM_Report",
    permissionKey: "Employee_Hours",
  },

  {
    path: "/location",
    name: "location",
    component: Location,
    objectkey: "Master",
    permissionKey: "Location",
  },

  {
    path: "/vertical",
    name: "vertical",
    component: Vertical,
    objectkey: "Master",
    permissionKey: "Vertical",
  },
  {
    path: "/attendance-device",
    name: "AttendanceDevice",
    component: AttendanceDevice,
    objectkey: "Master",
    permissionKey: "Attendance_Device",
  },
  {
    path: "/configuration",
    name: "Configuration",
    component: MailSetting,
    objectkey: "Master",
    permissionKey: "Configuration",
  },
  {
    path: "/escalationType",
    name: "Escalationc Type",
    component: EscalationType,
    objectkey: "Master",
    permissionKey: "EscalationType",
  },
  {
    path: "/shiftMaster",
    name: "Shift Master",
    component: ShiftMasterList,
    objectkey: "Master",
    permissionKey: "ShiftMaster",
  },
  {
    path: "/overtime",
    name: "overtime",
    component: Overtime,
  },
  {
    path: "/employeePresentReport",
    name: "employeePresentReport",
    component: EmployeePresentReport,
  },
  {
    path: "/dueDashboard",
    name: "dueDashboard",
    component: DueDashboard,
    objectkey: "PM_Report",
    permissionKey: "Dues",
  },
  {
    path: "/add-team",
    name: "addteam",
    component: AddTeamMember,
    objectkey: "PM_Report",
    permissionKey: "Task_Timer_Report",
  },
  {
    path: "/teams-leave",
    name: "teamsleave",
    component: TeamsLeaves,
    objectkey: "PM_Report",
    permissionKey: "Task_Timer_Report",
  },
  {
    path: "/teams-kra",
    name: "teamskra",
    component: TeamKra,
  },
  {
    path: "/employee-tracker",
    name: "employee-tracker",
    component: EmployeeTracker,
    objectkey: "PM_Report",
    permissionKey: "Employee_Tracker",
  },
  {
    path: "/story-tracker",
    name: "story-tracker",
    component: StoryTracker,
    objectkey: "PM_Report",
    permissionKey: "Task_Timer_Report",
  },
  {
    path: "/Project-Working-Hours",
    name: "Project-Working-Hours",
    component: ProjectWorkingHours,
    objectkey: "PM_Report",
    permissionKey: "Project_Working_Hours",
  },
  {
    path: "/manualAttendanceRequestReport",
    name: "manualAttendanceRequestReport",
    component: ManualAttendanceReport,
  },
  {
    path: "/checkoutRequest",
    name: "manualCheckoutRequestReport",
    component: ManualCheckOutRequest,
  },
  {
    path: "/checkInRequest",
    name: "manualCheckInRequestReport",
    component: ManualCheckInRequest,
    objectkey: "Report",
    permissionKey: "Manual_Attendance_Request",
  },
  {
    path: "/KRA",
    name: "KRA",
    component: KRA,
  },
  {
    path: "/EmployeeWorkingHours",
    name: "EmployeeWorkingHours",
    component: EmployeeWorkingHours,
    objectkey: "Report",
    permissionKey: "Attendance_Summary",
  },
  {
    path: "/TLKRA",
    name: "TLKRA",
    component: TLKRA,
    objectkey: "KRA",
    permissionKey: "TL_HOD_KRA",
  },
  {
    path: "/TLViewKra",
    name: "TLViewKra",
    component: TLViewKra,
  },

  {
    path: "/milestonesList",
    name: "milestonesList",
    component: MilestonesList,
    objectkey: "PM_Report",
    permissionKey: "Milestone_Report",
  },

  {
    path: "/milestonesList2",
    name: "milestonesList2",
    component: MilestoneList2,
    objectkey: "PM_Report",
    permissionKey: "Milestone_Report",
  },

  {
    path: "/storyList",
    name: "storyList",
    component: StoryList,
    objectkey: "PM_Report",
    permissionKey: "Milestone_Report",
  },

  {
    path: "/storyList2",
    name: "storyList2",
    component: StoryReport2,
  },

  //   },
  //region end
];

export const ProjectRoutes = [];
