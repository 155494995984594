import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import React, { useMemo, useRef, useState } from "react";
import AxiosInstance from "../../service/AxiosInstance";

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
} // Usage of DebounceSelect

async function fetchUserList(username) {
  let employee = [];
  await AxiosInstance.get(`auth/employees-search?q=${username}`).then(
    async (res) => {
      employee = res.data.data.map((user) => ({
        label: `${user.First_name} ${user.Middle_name} ${user.Last_name} `,
        value: user._id,
      }));
    }
  );
  console.log("fetching user", username, employee);
  return employee;
}

const SelectEmpployee = ({ setEmployeeIds, mode, initialvalue = [] }) => {
  const [value, setValue] = useState(initialvalue);

  const handleOnChange = (value) => {
    console.log(value);
    const modeSelection = mode == "multiple" ? "multiple" : "single";
    if (modeSelection === "single") {
      if (value.length > 0) {
        setValue(value[value.length - 1]);
      } else {
        setValue([]);
      }
    } else {
      setValue(value);
    }

    let selectedIds = [];
    value.forEach((element) => {
      selectedIds.push(element.value);
    });
    setEmployeeIds(selectedIds);
  };

  return (
    <>
      <DebounceSelect
        mode="multiple"
        value={value}
        placeholder="Select users"
        fetchOptions={fetchUserList}
        onChange={(newValue) => {
          //setValue(newValue);
          handleOnChange(newValue);
        }}
        style={{
          width: "100%",
        }}
      />
    </>
  );
};

export default SelectEmpployee;
