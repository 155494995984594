import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  message,
  Table,
  Checkbox,
  TimePicker,
  Typography,
  DatePicker,
} from "antd";
import AxiosInstance from "../../../../service/AxiosInstance";
import moment from "moment";
import { Grid } from "@mui/material";

const { Title } = Typography;

function AddShiftMaster({ setIsOpen, reloadList, editData }) {
  const [form] = Form.useForm();
  const [checkboxStates, setCheckboxStates] = useState({
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  });

  // State for error messages
  const [errorMessages, setErrorMessages] = useState({
    fullDay: {},
    halfDay: {},
  });

  const handleCancel = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const validateHours = (values, day) => {
    const startTime = moment(values[`startTime${day}`]);
    const endTime = moment(values[`endTime${day}`]);

    if (endTime.isBefore(startTime)) {
      return { fullDay: "End Time must be after Start Time" };
    }

    const diffInMinutes = Math.round(endTime.diff(startTime, "minutes", true)); // use true for float difference

    const fullDayHours = values[`fullDay${day}`] || 0;
    const halfDayHours = values[`halfDay${day}`] || 0;

    const errors = {};

    if (fullDayHours > diffInMinutes) {
      errors.fullDay = `Full Day Hours cannot exceed available time`;
    }

    if (halfDayHours > diffInMinutes) {
      errors.halfDay = `Half Day Hours cannot exceed available time`;
    }

    return errors;
  };

  const onFinish = (values) => {
    // console.log(values, 69696969);

    const days = [];
    const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    let isAnyDayFilled = false;
    let hasUncheckedFilledDays = false;

    weekDays.forEach((day) => {
      const startTime = values[`startTime${day}`];
      const endTime = values[`endTime${day}`];
      const fullDay = values[`fullDay${day}`];
      const halfDay = values[`halfDay${day}`];

      if (
        (startTime || endTime || fullDay || halfDay) &&
        !checkboxStates[day]
      ) {
        hasUncheckedFilledDays = true;
      }

      if (checkboxStates[day]) {
        const errorMessage = validateHours(values, day);
        if (Object.keys(errorMessage).length === 0) {
          isAnyDayFilled = true;
          days.push({
            day: day,
            startTime: moment(startTime).format("HH:mm"),
            endTime: moment(endTime).format("HH:mm"),
            fullDayHours: fullDay || 0,
            halfDayHours: halfDay || 0,
          });
        }
      }
    });

    // Show one error message and stop submission
    if (hasUncheckedFilledDays) {
      message.error(
        // "Please ensure all days with filled data have checkboxes selected."
        "Please select checkbox for days with filled data."
      );
      return;
    }

    // If no checkbox is selected, show error and stop submission
    if (!isAnyDayFilled) {
      message.error("Please select and fill valid data.");
      return;
    }

    const urlencoded = new URLSearchParams();
    urlencoded.append("shiftName", values?.shiftName);
    urlencoded.append(
      "startDate",
      values?.startDate ? moment(values?.startDate).format("YYYY-MM-DD") : ""
    );
    urlencoded.append(
      "endDate",
      values?.endDate ? moment(values?.endDate).format("YYYY-MM-DD") : ""
    );
    urlencoded.append("days", JSON.stringify(days));
   

    if (editData?._id) {
      urlencoded.append("shiftId", editData?._id);
    }

    AxiosInstance.post("/auth/shift-master/add-edit", urlencoded)
      .then((res) => {
        if (res?.data?.status === 1) {
          message.success(res?.data?.message);
          reloadList();
          setIsOpen(false);
        } else {
          message.error(res?.data?.message);
          reloadList();
          form.setFields({
            shiftName: {
              errors: [{ message: res?.data?.message }],
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    form.setFieldsValue({});
  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCheckboxChange = (day) => {
    const newCheckboxStates = {
      ...checkboxStates,
      [day]: !checkboxStates[day],
    };
    setCheckboxStates(newCheckboxStates);
  };

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    const newCheckboxStates = Object.keys(checkboxStates).reduce((acc, day) => {
      acc[day] = checked;
      return acc;
    }, {});
    setCheckboxStates(newCheckboxStates);
  };

  const columns = [
    {
      title: (
        <Checkbox
          onChange={handleSelectAll}
          checked={Object.values(checkboxStates).every(Boolean)}
        />
      ),
      dataIndex: "select",
      render: (_, record) => (
        <Checkbox
          checked={checkboxStates[record.day]}
          onChange={() => handleCheckboxChange(record.day)}
        />
      ),
    },
    {
      title: <span className="light-bold">Day</span>,
      dataIndex: "day",
      render: (text) => <span>{text}</span>,
    },

    {
      title: <span className="light-bold">Start Time</span>,
      dataIndex: "startTime",
      render: (_, record) => (
        <Form.Item
          name={`startTime${record.day}`}
          rules={[
            {
              required: checkboxStates[record.day],
              message: "Please select Start Time",
            },
          ]}
        >
          <TimePicker format="HH:mm" placeholder="HH:MM" />
        </Form.Item>
      ),
    },
    {
      title: <span className="light-bold">End Time</span>,
      dataIndex: "endTime",
      render: (_, record) => (
        <Form.Item
          name={`endTime${record.day}`}
          rules={[
            {
              required: checkboxStates[record.day],
              message: "Please select End Time",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const startTime = getFieldValue(`startTime${record.day}`);
                if (
                  startTime &&
                  value &&
                  moment(value, "HH:mm").isBefore(moment(startTime, "HH:mm"))
                ) {
                  return Promise.reject(
                    new Error("End Time must be after Start Time")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <TimePicker format="HH:mm" placeholder="HH:MM" />
        </Form.Item>
      ),
    },
    {
      title: <span className="light-bold">Full Day Hours</span>,
      dataIndex: "fullDay",
      render: (_, record) => (
        <>
          <Form.Item
            name={`fullDay${record.day}`}
            rules={[
              {
                required: checkboxStates[record.day],
                message: "Please enter Full Day Hours",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Minutes"
              onChange={(e) => {
                const value = e.target.value;
                form.setFieldsValue({ [`fullDay${record.day}`]: value });

                const errorMessage = validateHours(
                  form.getFieldsValue(),
                  record.day
                );
                if (errorMessage.fullDay) {
                  setErrorMessages((prev) => ({
                    ...prev,
                    fullDay: {
                      ...prev.fullDay,
                      [record.day]: errorMessage.fullDay,
                    },
                  }));
                } else {
                  setErrorMessages((prev) => ({
                    ...prev,
                    fullDay: { ...prev.fullDay, [record.day]: null },
                  }));
                }
              }}
            />
          </Form.Item>
          {errorMessages.fullDay[record.day] && (
            <div style={{ color: "red" }}>
              {errorMessages.fullDay[record.day]}
            </div>
          )}
        </>
      ),
    },
    {
      title: <span className="light-bold">Half Day Hours</span>,
      dataIndex: "halfDay",
      render: (_, record) => (
        <>
          <Form.Item
            name={`halfDay${record.day}`}
            rules={[
              {
                required: checkboxStates[record.day],
                message: "Please enter Half Day Hours",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Minutes"
              onChange={(e) => {
                const value = e.target.value;
                form.setFieldsValue({ [`halfDay${record.day}`]: value });

                // Validate Half Day Hours
                const errorMessage = validateHours(
                  form.getFieldsValue(),
                  record.day
                );
                if (errorMessage.halfDay) {
                  setErrorMessages((prev) => ({
                    ...prev,
                    halfDay: {
                      ...prev.halfDay,
                      [record.day]: errorMessage.halfDay,
                    },
                  }));
                } else {
                  setErrorMessages((prev) => ({
                    ...prev,
                    halfDay: { ...prev.halfDay, [record.day]: null },
                  }));
                }
              }}
            />
          </Form.Item>
          {errorMessages.halfDay[record.day] && (
            <div style={{ color: "red" }}>
              {errorMessages.halfDay[record.day]}
            </div>
          )}
        </>
      ),
    },
  ];

  const data = [
    { key: "1", day: "Mon" },
    { key: "2", day: "Tue" },
    { key: "3", day: "Wed" },
    { key: "4", day: "Thu" },
    { key: "5", day: "Fri" },
    { key: "6", day: "Sat" },
    { key: "7", day: "Sun" },
  ];

  useEffect(() => {
    if (editData) {
      const initialValues = {
        shiftName: editData.shiftName,
        startDate: editData.startDate ? moment(editData.startDate) : null,
        endDate: editData.endDate ? moment(editData.endDate) : null,
      };

      editData.days.forEach((day) => {
        initialValues[`startTime${day.day}`] = moment(day.startTime);
        initialValues[`endTime${day.day}`] = moment(day.endTime);

        // Set Full Day and Half Day Hours directly as numbers
        initialValues[`fullDay${day.day}`] = day.fullDayHours || 0;
        initialValues[`halfDay${day.day}`] = day.halfDayHours || 0;

        setCheckboxStates((prev) => ({ ...prev, [day.day]: true }));
      });

      console.log("Initial Values: ", initialValues);
      form.setFieldsValue(initialValues);
    }
  }, [editData, form]);

  return (
    <Modal
      title={editData?._id ? "Edit Shift Master" : "Add Shift Master"}
      open={true}
      onCancel={handleCancel}
      footer={null}
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="modal-grid-table-shift">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Form.Item
                name="shiftName"
                className="light-bold"
                label="Shift Name"
                rules={[{ required: true, message: "Please enter Shift Name" }]}
              >
                <Input placeholder="Shift Name" />
              </Form.Item>
            </Grid>
            <Grid item xs={4}>
              <Form.Item
                name="startDate"
                className="light-bold"
                label="Start Date"
              >
                <DatePicker format="DD-MM-YYYY" />
              </Form.Item>
            </Grid>
            <Grid item xs={4}>
              <Form.Item
                name="endDate"
                className="light-bold"
                label="End Date"
              >
                <DatePicker format="DD-MM-YYYY" />
              </Form.Item>
            </Grid>
          </Grid>
        </div>

        {/* <Title level={4}>Set Shift Hours</Title> */}
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered
          rowKey="key"
        />

        <Form.Item className="">
          <div className="main-sumbit-button">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button onClick={handleCancel} style={{ marginLeft: "8px" }}>
              Cancel
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddShiftMaster;
