import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Select, message } from "antd";
import AxiosInstance from "../../../service/AxiosInstance";
import { Breadcrumb, Spin, Form } from "antd";
import {
  HomeOutlined,
  FolderOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { useParams, useHistory, Link, useLocation } from "react-router-dom";
import { ChangeEscalationStatus } from "../../../redux/actions/EscalationStatusActions";
import downloadPDFIcon from "../../../assets/images/downloadPDF.png";
import parse from "html-react-parser";
import "jspdf-autotable";
import jsPDF from "jspdf";
import {
  MDBCardBody,
  MDBTypography,
  MDBCol,
  MDBRow,
  MDBIcon,
  ardBody,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownLink,
} from "mdb-react-ui-kit";
import EscalationComment from "../comment/EscalationComment";
import { useDispatch } from "react-redux";
import moment from "moment";
const { Option } = Select;

const getSeverityColor = (severity) => {
  switch (severity) {
    case "RED":
      return "red";
    case "GREEN":
      return "green";
    case "YELLOW":
      return "#ffa900";
    case "N/A":
      return "grey";
    default:
      return "grey";
  }
};

function ViewEscalation({ escalationId, setIsViewOpen, isViewOpen }) {
  let history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const User = JSON.parse(localStorage.getItem("userdata"));
  const [escalationData, setEscalationData] = useState("");
  const [loading, setLoading] = useState(true);
  const [commentsData, setCommentsData] = useState([]);
  const [statusUpdate, setStatusUpdate] = useState("");
  const [myValue, setMyValue] = useState("");
  const previousValue = useRef();
  const [changeEscalation, setChangeEscalation] = useState(false);
  const [lastStatusDb, setLastStatusDb] = useState("");
  const [isOpenCloseModal, setIsOpenCloseModal] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");

  const severityColor = getSeverityColor(escalationData?.Severity);

  useEffect(() => {
    if (params.id) {
      const formData = new URLSearchParams();
      formData.append("id", params.id);

      AxiosInstance.get(`/auth/get/SingleEscalation/${params.id}`)
        .then((res) => {
          if (res?.data?.status === 1) {
            setEscalationData(res?.data?.data);
            setMyValue(res?.data?.data?.Status);
          } else {
            // handle error
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  }, [params.id, changeEscalation]);

  function addAttributeToTagInString(
    string,
    tagName,
    attributeName,
    attributeValue
  ) {
    const parser = new DOMParser();
    const document = parser.parseFromString(string, "text/html");
    const tag = document.querySelector(tagName);
    if (tag) {
      tag.setAttribute(attributeName, attributeValue);
    }
    return document.documentElement.innerHTML;
  }

  const string = escalationData?.Description;
  const stepsString = escalationData?.Steps_to_reproduce;
  let modifiedDescription = addAttributeToTagInString(
    string,
    "a",
    "target",
    "_blank"
  );
  let modifiedStepsToReproduce = addAttributeToTagInString(
    stepsString,
    "a",
    "target",
    "_blank"
  );
  modifiedDescription = modifiedDescription
    .replace("<head>", "")
    .replace("</head>", "")
    .replace("<body>", "")
    .replace("</body>", "");
  modifiedStepsToReproduce = modifiedStepsToReproduce
    .replace("<head>", "")
    .replace("</head>", "")
    .replace("<body>", "")
    .replace("</body>", "");

  let statusBg = "shadow-0";
  if (escalationData?.Status == "OPEN") {
    statusBg = "badge-light";
  } else if (escalationData?.Status == "WORKING ON CONFLICT REPORT") {
    statusBg = "status-blue";
  } else if (escalationData?.Status == "CLOSE") {
    statusBg = "status-green";
  }

  const statusChange = (st) => {
    dispatch(ChangeEscalationStatus());
    let formData = new URLSearchParams();
    formData.append("id", escalationData?._id);
    formData.append("Status", st);
    formData.append("FavourById", selectedEmployeeId);

    AxiosInstance.post("auth/edit/EscalationStatus", formData)
      .then((res) => {
        if (res?.data?.status == 1) {
          setStatusUpdate(st);
        } else {
          message.error(res?.data?.message);
        }
      })
      .catch((err) => console.log(err, "err"));
  };

  useEffect(() => {
    previousValue.current = lastStatusDb;
  }, [lastStatusDb]);

  const changeStatus = (value) => {
    dispatch(ChangeEscalationStatus());
    previousValue.current = myValue;
    setMyValue(value);
    // create history
    const formData1 = new URLSearchParams();
    formData1.append("Escalation_id", escalationData?._id);
    formData1.append(
      "Updated_by",
      User[0]?.First_name + " " + User[0]?.Last_name
    );
    formData1.append("Current_Status", `${value}`);
    formData1.append("Previous_Status", `${previousValue.current}`);
    AxiosInstance.post(
      "auth/history/add-edit-escalation-history",
      formData1
    ).then((res) => {
      setChangeEscalation(!changeEscalation);
      getHistoryDetails();
      if (res.data.status == 1) {
      }
    });
  };

  const getHistoryDetails = async () => {
    const response = await AxiosInstance.get(
      `auth/escalation-history/${params.id}`
    ).then(() => {});
  };

  const commentList = async () => {
    const formData = new URLSearchParams();
    formData.append("Escalation_id", params.id);
    AxiosInstance.post("auth/escalation/comments", formData).then(
      async (res) => {
        if (res?.data?.status === 1) {
          setCommentsData(res?.data?.data);
        }
      }
    );
  };
  useEffect(() => {
    commentList();
  }, []);

  // Function to format the time ago
  const formatTimeAgo = (date) => {
    const current = Date.now();
    const before = new Date(date).getTime();
    const difference = current - before;
    const days = Math.floor(difference / (24 * 60 * 60 * 1000));
    const hours = Math.floor(difference / (60 * 60 * 1000));
    const minutes = Math.floor(difference / (60 * 1000));
    if (days > 0) return `${days} days ago`;
    if (hours > 0) return `${hours} hours ago`;
    if (minutes === 0 || minutes === 1) return `Just now`;
    return `${minutes} minutes ago`;
  };

  const formatTextWithURLs = (text) => {
    const URL_REGEX =
      /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm;
    return text
      .split(/(\s+)/)
      .map((word) => (word.match(URL_REGEX) ? ` ${word} ` : word))
      .join("");
  };

  const convertHTMLToStyledText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    let text = "";

    const processNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const tagName = node.tagName.toLowerCase();
        let content = Array.from(node.childNodes).map(processNode).join("");

        switch (tagName) {
          case "p":
            return `\n${content}\n`;
          case "ul":
          case "ol":
            return `\n${content}\n`; // Lists with extra new lines for spacing
          case "li":
            return `• ${content}\n\n\n`; // Bullet points for list items with extra newline and space
          case "a":
            return `${content} (${node.href})`; // Links with URL
          case "strong":
          case "b":
            return `<b>${content}</b>`;
          case "em":
          case "i":
            return `<i>${content}</i>`;
          default:
            return content;
        }
      }
      return "";
    };

    text = processNode(doc.body);
    return text;
  };

  const addStyledTextToPDF = (pdfDoc, text, startY, marginRight, marginTop) => {
    let y = startY;
    const pdfWidth = pdfDoc.internal.pageSize.width;
    const lineHeight = 12;
    const marginAfterText = 10;

    const lines = text.split("\n").filter((line) => line.trim() !== "");
    lines.forEach((line) => {
      let isBold = false;
      let isItalic = false;

      if (line.includes("<b>")) {
        isBold = true;
        line = line.replace(/<\/?b>/g, "");
      }
      if (line.includes("<i>")) {
        isItalic = true;
        line = line.replace(/<\/?i>/g, "");
      }

      // Set the font style
      if (isBold && isItalic) {
        pdfDoc.setFont("helvetica", "bolditalic");
      } else if (isBold) {
        pdfDoc.setFont("helvetica", "bold");
      } else if (isItalic) {
        pdfDoc.setFont("helvetica", "italic");
      } else {
        pdfDoc.setFont("helvetica", "normal");
      }

      const maxLineWidth = pdfWidth - marginRight * 2;
      let textSegments = pdfDoc.splitTextToSize(line, maxLineWidth);

      textSegments.forEach((segment) => {
        if (y + lineHeight > pdfDoc.internal.pageSize.height - marginTop) {
          pdfDoc.addPage();
          y = marginTop;
        }

        pdfDoc.text(segment, marginRight, y);
        y += lineHeight;
      });

      y += marginAfterText;
    });

    return y;
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const marginRight = 40;
    const marginTop = 100;
    const detailsFontSize = 12;
    const normalFontSize = 11;
    const commentsHeadingMargin = 30;
    const usernameSpacing = 20;
    const commentSpacing = 24;

    const doc = new jsPDF(orientation, unit, size);

    const title = "Escalation Details Report";
    const createdDate = `Date: ${moment(escalationData?.createdAt).format(
      "DD-MM-YYYY"
    )}`;
    const headers = [
      [
        "Employee Name",
        "Escalation Type",
        "Project Name",
        "Severity",
        "Created By",
      ],
    ];

    const combinedComments = commentsData.map((comment) => {
      const userName = comment
        ? `${comment.First_name} ${comment.Last_name}`
        : "-";
      const timeAgo = formatTimeAgo(comment.Create_at);
      const commentText = formatTextWithURLs(comment.Comment);
      return { userName, timeAgo, commentText };
    });

    const data = [
      [
        escalationData?.EmployeeId
          ? `${escalationData?.EmployeeId?.First_name} ${escalationData?.EmployeeId?.Last_name}`
          : "-",
        escalationData?.EscalationTypeId?.Escalation_Type || "-",
        escalationData?.ProjectId?.Project_name || "-",
        escalationData?.Severity || "-",
        escalationData?.Created_by
          ? `${escalationData?.Created_by?.First_name} ${escalationData?.Created_by?.Last_name}`
          : "-",
      ],
    ];

    let content = {
      startY: 70,
      head: headers,
      body: data,
    };

    const pdfWidth = doc.internal.pageSize.getWidth();
    const textMargin = 45;
    const createdDateX = pdfWidth - textMargin;
    const createdDateY = marginTop - 40;

    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text(title, pdfWidth / 2, 30, { align: "center" });

    doc.setFont("helvetica", "normal");
    doc.setFontSize(11);
    doc.text(createdDate, createdDateX, createdDateY, { align: "right" });

    doc.autoTable(content);

    const detailsStartY = doc.autoTable.previous.finalY + 20;
    doc.setFont("helvetica", "bold");
    doc.setFontSize(detailsFontSize);
    doc.text("Escalation Details:", marginRight, detailsStartY);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(normalFontSize);

    const descriptionHTML = escalationData?.Description || "-";
    const descriptionStyledText = convertHTMLToStyledText(descriptionHTML);

    const finalY = addStyledTextToPDF(
      doc,
      descriptionStyledText,
      detailsStartY + 20,
      marginRight,
      marginTop
    );

    const addComments = (startY) => {
      let y = startY + commentsHeadingMargin;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(detailsFontSize);
      doc.text("Comments:", marginRight, y);
      y += commentsHeadingMargin;

      combinedComments.forEach((comment) => {
        if (
          y + usernameSpacing + commentSpacing >
          doc.internal.pageSize.height - marginTop
        ) {
          doc.addPage();
          y = marginTop;
        }

        const textX = marginRight;
        const userNameTime = `${comment.userName} (${comment.timeAgo})`;

        doc.setFont("helvetica", "normal");
        doc.setFontSize(normalFontSize);
        doc.text(userNameTime, textX, y);
        y += usernameSpacing;

        const commentTextLines = doc.splitTextToSize(
          comment.commentText,
          pdfWidth - marginRight * 2
        );
        commentTextLines.forEach((line) => {
          if (y + 12 > doc.internal.pageSize.height - marginTop) {
            doc.addPage();
            y = marginTop;
          }
          doc.text(line, textX, y);
          y += 12;
        });

        y += commentSpacing;
      });
    };

    addComments(finalY);

    doc.save(
      `${
        escalationData?.EmployeeId
          ? `${escalationData?.EmployeeId?.First_name} ${escalationData?.EmployeeId?.Last_name}`
          : "-"
      }_${escalationData?.EscalationTypeId?.Escalation_Type || "-"}_${moment(
        escalationData?.createdAt
      ).format("DD-MM-YYYY")}.pdf`
    );
  };

  const handleCancel = () => {
    setIsOpenCloseModal(false);
  };

  const handleSubmit = () => {
    statusChange("CLOSE");
    changeStatus("CLOSE");
    setIsOpenCloseModal(false);
  };

  return (
    <>
      <Modal
        title="Close this escalation?"
        centered
        open={isOpenCloseModal}
        onOk={() => setIsOpenCloseModal(false)}
        onCancel={handleCancel}
        width={380}
        footer={null}
      >
        <Select
          style={{ width: "100%", marginBottom: "16px" }}
          placeholder="Select your favour employee"
          onChange={(value) => setSelectedEmployeeId(value)}
        >
          <Option
            key={escalationData?.Created_by?._id}
            value={escalationData?.Created_by?._id}
          >
            {`${escalationData?.Created_by?.First_name} ${escalationData?.Created_by?.Last_name}`}
          </Option>
          <Option
            key={escalationData?.EmployeeId?._id}
            value={escalationData?.EmployeeId?._id}
          >
            {`${escalationData?.EmployeeId?.First_name} ${escalationData?.EmployeeId?.Last_name}`}
          </Option>
        </Select>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            className="pms-same-btn"
            type="danger"
            onClick={handleSubmit}
            style={{ marginRight: "7%" }}
          >
            Submit
          </Button>
          <Button onClick={handleCancel} className="pms-same-btn-cancel">
            Cancel
          </Button>
        </div>
      </Modal>
      <div className="demo">
        <div className="cardbackground">
          <div className="cardmargine">
            <MDBCardBody style={{ padding: "10px 15px" }}>
              <MDBTypography blockquote className="mb-0">
                <div>
                  <div className="view-task-bread">
                    <Breadcrumb>
                      <Breadcrumb.Item href="#">
                        <HomeOutlined />
                      </Breadcrumb.Item>
                      <Breadcrumb.Item href="#projects">
                        <FolderOutlined />
                        <span>View Escalation </span>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </div>
                  <MDBRow className="mx-0 pro-task-details-res">
                    <MDBCol
                      size="9"
                      className="mt-20 left-task-view"
                      style={{ paddingLeft: "0px" }}
                    >
                      <div
                        className="TaskKeyTitle"
                        style={{ margin: "0 0 10px 0" }}
                      >
                        <MDBRow className="mx-0">
                          <MDBCol
                            style={{ textAlign: "right", padding: "0px" }}
                          >
                            <span>
                              <>
                                <Button
                                  type="button"
                                  onClick={() => exportPDF()}
                                >
                                  <img
                                    src={downloadPDFIcon}
                                    className="download-pdf-backlog"
                                  />
                                </Button>
                              </>

                              <Button
                                onClick={() => history.goBack()}
                                style={{ marginLeft: "10px" }}
                                className="pms-same-btn-cancel"
                              >
                                <MDBIcon
                                  style={{ paddingRight: "5px" }}
                                  fas
                                  icon="long-arrow-alt-left"
                                />
                                Back
                              </Button>
                            </span>
                          </MDBCol>
                        </MDBRow>
                      </div>

                      <div className="TitleStyle">Description</div>

                      <div
                        className="d-flex align-items-start bg-light scrollbar"
                        id="style-7"
                        style={{
                          fontWeight: 2,
                        }}
                      >
                        <div
                          className="force-overflow"
                          style={{ padding: "7px 0" }}
                        >
                          <p
                            className="custom-ant-description"
                            style={{ margin: "0" }}
                          >
                            {escalationData?.Description
                              ? parse(modifiedDescription)
                              : ""}
                          </p>
                        </div>
                      </div>

                      <div className="activity-text">Activity</div>

                      <div className="emp-task-tab">
                        <EscalationComment
                          User={User}
                          changeEscalation={changeEscalation}
                          current={myValue}
                          previous={previousValue.current}
                          EscalationId={params.id}
                        />
                      </div>
                    </MDBCol>

                    <MDBCol
                      className="right-task-view"
                      size="3"
                      style={{ paddingLeft: "10px" }}
                    >
                      <div className="mx-0">
                        <MDBCol className="mt-20">
                          <MDBDropdown>
                            <MDBDropdownToggle
                              style={{
                                fontSize: "1rem",
                                padding: "0.3rem 1rem .3rem 1rem",
                              }}
                              className={statusBg}
                            >
                              {escalationData?.Status ? (
                                escalationData?.Status
                              ) : (
                                <Spin size="large" />
                              )}
                            </MDBDropdownToggle>

                            <MDBDropdownMenu>
                              <MDBDropdownItem>
                                <MDBDropdownLink
                                  onClick={() => {
                                    statusChange("OPEN");
                                    changeStatus("OPEN");
                                  }}
                                >
                                  OPEN
                                </MDBDropdownLink>
                              </MDBDropdownItem>

                              <MDBDropdownItem>
                                <MDBDropdownLink
                                  onClick={() => {
                                    statusChange("WORKING ON CONFLICT REPORT");
                                    changeStatus("WORKING ON CONFLICT REPORT");
                                  }}
                                >
                                  WORKING ON CONFLICT REPORT
                                </MDBDropdownLink>
                              </MDBDropdownItem>

                              <MDBDropdownItem>
                                <MDBDropdownLink
                                  onClick={() => {
                                    setIsOpenCloseModal(true);
                                  }}
                                >
                                  CLOSE
                                </MDBDropdownLink>
                              </MDBDropdownItem>
                            </MDBDropdownMenu>
                          </MDBDropdown>
                        </MDBCol>

                        <MDBCol className="mt-20 task-common">
                          <span className="TitleStyle left-col-description">
                            Project Name
                          </span>

                          <Link
                            style={{ margin: "0 0 0 15px", fontSize: "13px" }}
                            to={`/project/view/${escalationData?.ProjectId?._id}`}
                          >
                            {escalationData?.ProjectId?.Project_name
                              ? escalationData?.ProjectId?.Project_name
                              : "-"}
                          </Link>
                        </MDBCol>

                        <MDBCol className="mt-20 task-common">
                          <span className="TitleStyle left-col-description">
                            Escalation Type
                          </span>
                          <p style={{ margin: "0 0 0 15px" }}>
                            {escalationData?.EscalationTypeId?.Escalation_Type
                              ? escalationData?.EscalationTypeId
                                  ?.Escalation_Type
                              : "-"}
                          </p>
                        </MDBCol>

                        <MDBCol className="mt-20 task-common">
                          <span className="TitleStyle left-col-description">
                            Severity
                          </span>
                          <p style={{ margin: "0px" }}>
                            <span
                              style={{
                                margin: "0 0 0 15px",
                                fontSize: "10px",
                                backgroundColor: severityColor,
                                color: "white",
                                padding: "2px 5px",
                                borderRadius: "20px",
                              }}
                              className="badge"
                            >
                              {escalationData?.Severity
                                ? escalationData?.Severity
                                : "-"}
                            </span>
                          </p>
                        </MDBCol>
                      </div>

                      <div className="mx-0">
                        <MDBCol className="mt-20 task-common">
                          <span className="TitleStyle left-col-description">
                            Assignees
                          </span>

                          <p style={{ margin: "0px" }}>
                            <span
                              style={{
                                margin: "0 0 0 15px",
                                fontSize: "13px",
                              }}
                            >
                              {escalationData?.EmployeeId
                                ? `${escalationData?.EmployeeId?.First_name} ${escalationData?.EmployeeId?.Last_name}`
                                : "-"}
                            </span>
                          </p>
                        </MDBCol>

                        <MDBCol className="mt-20 task-common">
                          <span className="TitleStyle left-col-description">
                            Created By
                          </span>
                          <p style={{ margin: "0 0 0 15px" }}>
                            {escalationData?.Created_by
                              ? `${escalationData?.Created_by?.First_name} ${escalationData?.Created_by?.Last_name}`
                              : "-"}
                          </p>
                        </MDBCol>
                        <MDBCol className="mt-20 task-common">
                          <span className="TitleStyle left-col-description">
                            Created Date
                          </span>
                          <p style={{ margin: "0 0 0 15px" }}>
                            {moment(escalationData?.createdAt).format(
                              "DD-MM-YYYY"
                            )}
                          </p>
                        </MDBCol>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBTypography>
            </MDBCardBody>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewEscalation;
