import React, { useEffect, useState } from "react";
import { MDBCard, MDBCol } from "mdb-react-ui-kit";
import ReactLoader from "../../../../components/common/ReactLoader";
import AxiosInstance from "../../../../service/AxiosInstance";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from "@mui/material";
import { Button, message } from "antd";
import AddShiftMaster from "./AddShiftMaster";
import moment from "moment";
import Switch from "@mui/material/Switch";
import { Modal } from "antd";

function ShiftMasterList() {
  const [shiftMasterList, setShiftMasterList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [shiftmasterId, setShiftmasterId] = useState(null);

  // Fetching the shift master list
  const getAllShiftMasterList = async () => {
    try {
      const res = await AxiosInstance.get("/auth/shift-master/list");
      if (res?.data?.status === 1) {
        setShiftMasterList(res?.data?.data);
      } else {
        setShiftMasterList(res?.data?.data);
      }
    } catch (error) {
      message.error("Failed to load shift master list");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllShiftMasterList();
  }, []);

  const reloadList = () => {
    getAllShiftMasterList();
  };

  const deleteEscalation = async () => {
    try {
      const urlencoded = new URLSearchParams();
      urlencoded.append("shiftId", shiftmasterId);
      const res = await AxiosInstance.post(
        "/auth/shift-master/delete",
        urlencoded
      );
      if (res?.data?.status === 1) {
        message.success("Shift deleted successfully");
        setIsOpenDeleteModal(false);
        getAllShiftMasterList();
        reloadList();
      } else {
        message.error(res?.data?.message);
      }
    } catch (error) {
      message.error("Failed to delete shift master");
    } finally {
      setLoading(false);
    }
  };

  const changeStatusActive = async (item) => {
    if (!item) {
      message.error("Item not provided");
      return;
    }
    const urlencoded = new URLSearchParams();
    const newStatus = !item.isActive;

    urlencoded.append("shiftId", item._id);
    urlencoded.append("isActive", newStatus);

    try {
      const res = await AxiosInstance.post(
        "/auth/shift-master/action",
        urlencoded
      );
      if (res?.data?.status === 1) {
        const statusMessage = newStatus ? "activated" : "deactivated";
        message.success(`Shift ${statusMessage} successfully`);
        getAllShiftMasterList();
      } else {
        message.error(res?.data?.message || "Device ID not found");
      }
    } catch (error) {
      message.error("An error occurred while changing the shift status");
    }
  };

  const handleCancel = () => {
    setIsOpenDeleteModal(false);
    setShiftmasterId(null);
  };

  return (
    <div className="demo">
      <MDBCard className="page-content-shift">
        <div>
          <MDBCol size="md" className="col-example">
            <h3 className="tableHeader-shift">
              Shift Master
              <Button
                type="primary"
                onClick={() => {
                  setIsOpen(true);
                  setShiftmasterId(null);
                }}
                style={{ float: "right" }}
                className="pms-same-btn"
              >
                Add
              </Button>
              {isOpen && (
                <AddShiftMaster
                  inSidebar={true}
                  btnStyl={true}
                  reloadList={reloadList}
                  setIsOpen={setIsOpen}
                  editData={shiftmasterId}
                />
              )}
            </h3>
          </MDBCol>
        </div>
        {loading ? (
          <ReactLoader />
        ) : (
          <div className="table-div-container-cus">
            <TableContainer
              className="table-container-custom"
              component={Paper}
            >
              <Table className="shift-table">
                <TableHead>
                  <TableRow className="table-row-highlight">
                    <TableCell className="table-cell-shift">
                      Shift Name
                    </TableCell>
                    <TableCell className="table-cell-shift">
                      Start Date
                    </TableCell>
                    <TableCell className="table-cell-shift">End Date</TableCell>
                    <TableCell className="table-cell-shift">Days</TableCell>
                    <TableCell className="table-cell-shift">
                      Start Time
                    </TableCell>
                    <TableCell className="table-cell-shift">End Time</TableCell>
                    <TableCell className="table-cell-shift">
                      Full Day Hours (In Min)
                    </TableCell>
                    <TableCell className="table-cell-shift">
                      Half Day Hours (In Min)
                    </TableCell>
                    <TableCell className="table-cell-shift">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shiftMasterList.length > 0 ? (
                    shiftMasterList.map((shift) =>
                      shift.days.map((day, index) => {
                        const startTime = moment(day.startTime).format("HH:mm");
                        const endTime = moment(day.endTime).format("HH:mm");
                        const fullDayHours = day.fullDayHours;
                        const halfDayHours = day.halfDayHours;
                        return (
                          <TableRow key={`${shift._id}-${index}`}>
                            {index === 0 && (
                              <>
                                <TableCell
                                  rowSpan={shift.days.length}
                                  className="shift-name-cell"
                                >
                                  {shift.shiftName}
                                </TableCell>
                                <TableCell
                                  rowSpan={shift.days.length}
                                  // className="shift-name-cell"
                                >
                                  {shift.startDate
                                    ? moment(shift.startDate).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  rowSpan={shift.days.length}
                                  // className="shift-name-cell"
                                >
                                  {shift.endDate
                                    ? moment(shift.endDate).format("DD-MM-YYYY")
                                    : "-"}
                                </TableCell>
                              </>
                            )}
                            <TableCell className="day-cell">
                              {day.day}
                            </TableCell>
                            <TableCell className="day-cell">
                              {startTime}
                            </TableCell>
                            <TableCell className="day-cell">
                              {endTime}
                            </TableCell>
                            <TableCell className="day-cell">
                              {fullDayHours}
                            </TableCell>
                            <TableCell className="day-cell">
                              {halfDayHours}
                            </TableCell>
                            {index === 0 && (
                              <TableCell
                                rowSpan={shift.days.length}
                                className="actions-cell"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <Switch
                                    checked={shift.isActive}
                                    onChange={() => changeStatusActive(shift)}
                                    className="status-switch"
                                  />
                                  <Button
                                    type="primary"
                                    onClick={() => {
                                      setIsOpen(true);
                                      setShiftmasterId(shift);
                                    }}
                                  >
                                    <EditOutlined />
                                  </Button>
                                  <Button
                                    danger
                                    className="dlt-btn ant-btn-dangerous ant-btn-primary"
                                    type="primary"
                                    onClick={() => {
                                      setShiftmasterId(shift._id);
                                      setIsOpenDeleteModal(true);
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </div>
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9}>No data available</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </MDBCard>

      {/* Delete Modal */}
      <Modal
        title="Are you sure to delete this record?"
        centered
        open={isOpenDeleteModal}
        onOk={() => setIsOpenDeleteModal(false)}
        onCancel={handleCancel}
        width={380}
        footer={null}
        className="delete-modal"
      >
        <Button
          className="pms-same-btn"
          type="danger"
          onClick={deleteEscalation}
          style={{ marginRight: "7%" }}
        >
          Delete
        </Button>
        <Button onClick={handleCancel} className="pms-same-btn-cancel">
          Cancel
        </Button>
      </Modal>
    </div>
  );
}

export default ShiftMasterList;
